
import './PaidProductBlock.css'

import { MiniCarousel } from "../../standarts/mini-carousel/MiniCarousel";
import PaidProduct from "../../paid-product/PaidProduct";
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ky from 'ky';
import Cookies from 'js-cookie';

function PaidProductBlock() {

    const [products, setProducts] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const formData = new FormData();
            formData.append('token', Cookies.get('token'));
            const response = (await ky.post('https://topkaproduct.com:8443/product/getProducts', {
                body: formData,
            }).json());

            if (response.status === "error") {
                // console.log(response)
                return;
            }

            setProducts(response.products.
                filter((e) => {
                    return e.paid;
                }).
                map((e) => {
                return (
                    // <MainBlock name={e.title} description={e.desc} buttonText={e.button_text} image={e.image} backgroundImage={e.background} buttonLink={e.button_link} />
                    <PaidProduct key={uuidv4()} productId={e.id} icon={e.svg_mini_icon} name={e.name} description={e.short_description} />
                );
            }));

        }
        fetchData().then(r => r)
        // setProducts([
        //     <PaidProduct key={uuidv4()} icon='AutoBuy' name='Topka AutoBuy' description='C помощью этого мода вы можете Автоматически покупать ресурсы на аукционе.' />,
        //     <PaidProduct key={uuidv4()} icon='TopkaAutoMyst' name='Topka Visuals' description='C помощью этого мода вы можете менять цвет хитбокса игрока и не только.' />,
        //     <PaidProduct key={uuidv4()} icon='TopkaVisual' name='Topka Automyst' description='C помощью этого мода вы можете менять цветхитбокса игрока и не только.' />,

        //     <PaidProduct key={uuidv4()} icon='AutoBuy' name='Topka AutoBuy' description='C помощью этого мода вы можете Автоматически покупать ресурсы на аукционе.' />,
        //     <PaidProduct key={uuidv4()} icon='TopkaAutoMyst' name='Topka Visuals' description='C помощью этого мода вы можете менять цвет хитбокса игрока и не только.' />,
        //     <PaidProduct key={uuidv4()} icon='TopkaVisual' name='Topka Automyst' description='C помощью этого мода вы можете менять цветхитбокса игрока и не только.' />,

        //     <PaidProduct key={uuidv4()} icon='AutoBuy' name='Topka AutoBuy' description='C помощью этого мода вы можете Автоматически покупать ресурсы на аукционе.' />
        // ]);
    }, []);

    // useEffect(() => {
    //     console.log(products)
    // }, [products]);

    return (
        <>
            <div className="paid-product-block" id='paid-product'>
                <div className='full-version'>
                    <MiniCarousel>
                        {products}
                    </MiniCarousel>
                </div>
                
                <div className='mini-version'>
                    {products}
                </div>
            </div>
        </>
    );
}

export default PaidProductBlock;