
import { Children, cloneElement, useEffect, useState } from 'react';
import './CarouselPictures.css'

import arrow_left from './arrow_left.svg'
import arrow_right from './arrow_right.svg'


function CarouselPictures ({children}) {

    const [pages, setPages] = useState([]);

    const [index, setIndex] = useState(0);
    const [offset, setOffset] = useState(0);

    const onClickLeft = () => {
        if (index == 0) {
            setIndex(-(children.length-3));
        } else {
            setIndex(index + 1);
        }
    }
    
    const onClickRight = () => {
        if (index == -(children.length-3)) {
            setIndex(0);
        } else {
            setIndex(index - 1);
        }
    }

    useEffect(() => {
        setOffset(index * 33.333);
    }, [index]);

    return (
        <>
            <div className="picture-box">
                <div className='box' style={{
                    transform: `translateX(${offset}%)`
                }}>
                    {children}
                </div>
                <img className="arrow left" src={arrow_left} onClick={onClickLeft} />

                <img className="arrow right" src={arrow_right} onClick={onClickRight}/>
            </div>
        </>
    );
}

export default CarouselPictures;