import DynamicImage from '../header/DynamicImage';
import StandartButton from '../standarts/button/StandartButton';
import './FreeProduct.css'

import download_icon from './download_icon.svg'

function FreeProduct({productId, style, icon, name, description}) {

    const download = () => {
        window.location.href = `/product/${productId}`;
    }

    return (
    <>
        <div className='free-product-main-box'>
            <div className='icon-shell'>
                <div className='icon-zone'>
                    <DynamicImage mode={2} name={icon} className='icon' /> 
                </div>
                <div className='download-zone'>
                    <div className='count'>14 523</div>
                    <StandartButton className={'button-style-4'} onClick={download}>
                        <img className='img' src={download_icon} />
                    </StandartButton>
                </div>
            </div>
            
            <div className='name'>{name}</div>
            <div className='desc'>{description}</div>
        </div>
    </>
    );
}

export default FreeProduct;