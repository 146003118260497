

import DynamicImage from '../header/DynamicImage';
import './TopkaFooter.css';

import discord from './discord.svg'
import vk from './vk.svg'
import youtube from './youtube.svg'


function TopkaFooter() {
    return (
        <>
        <footer>
            <div className="topka-footer">
                <div className='mini-block'>
                    <div className="block-with-name-shell">
                        <div className="name">Topka product</div>
                    </div>
                </div>
                

                <div className="separator"/>

                <div className='other'>
                    <div className='social'>
                        <img src={discord} 
                            className='icon'
                            alt="Img"
                            onClick={() => {window.open('https://discord.gg/CtNVRkqPZu', '_blank');}}/>
                        <img src={youtube} 
                            className='icon'
                            alt="Img"
                            onClick={() => {window.open('https://www.youtube.com/@TOPKAPRODUCTT', '_blank');}}/>
                        <img src={vk} 
                            className='icon'
                            alt="Img"
                            onClick={() => {window.open('https://vk.com/mstresurs', '_blank');}}/>
        
                        {/* <DynamicImage folder='footer' name='discord' className='icon' onClick={() => {window.open('https://discord.gg/CtNVRkqPZu', '_blank');}} />
                        <DynamicImage folder='footer' name='youtube' className='icon' onClick={() => {window.open('https://www.youtube.com/@TOPKAPRODUCTT', '_blank');}}/>
                        <DynamicImage folder='footer' name='vk' className='icon'      onClick={() => {window.open('https://vk.com/mstresurs', '_blank');}}/> */}
                    </div>
                    
                    
                    <div className='all-rights'>
                        <div className='text'>Topka Product ©2023. All rights reserved.</div>
                        <div className='tos'>
                            <a className='no-text-decoration' href="../../../tos1.txt">Пользовательское соглашение</a>
                            <a className='no-text-decoration' href="../../../tos2.txt">Правила использования</a>
                            <a className='no-text-decoration' href="../../../tos3.txt">Обработка персональных данных</a>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </footer>
        </>
    );
}

export default TopkaFooter;