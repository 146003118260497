
import Cookies from 'js-cookie';
import './ProductVersion.css'

import buy from './button_buy.svg'
import download from './button_download.svg'
import ky from 'ky';
import StandartButton from '../button/StandartButton';

function ProductVersion ({id, name, api, file, svg_mini_icon, openDialogSubscribe}) {

    const downloadFile = async (fileUrl) => {
        if (Cookies.get('token') == null || Cookies.get('token') == undefined) {
            window.location.href = '/profile';
            return;
        }
        if (file === "needSubscribeBrat :)") {
            openDialogSubscribe(true);
            return;
        }
        const link = document.createElement('a');
        link.href = fileUrl;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const buyProduct = async () => {
        if (Cookies.get('token') == null || Cookies.get('token') == undefined) {
            window.location.href = '/profile';
            return;
        }
        const formData = new FormData();
        formData.append('id', id);
        formData.append('token', Cookies.get('token'));
        const response = await ky.post('https://topkaproduct.com:8443/product/getPaymentLink', {
            body: formData,
        }).json();

        if (response.status === "error") {
            // console.log(response)
            return;
        }

        window.location.href = response.link;
    };

    return (
        <>
            <div className="product-version">
                <div className='title-boxes'>
                    <div className='img-box'>
                        <img className="img" src={`https://topkaproduct.com:8443/files/image/${svg_mini_icon}`} alt="mini icon" />
                    </div>

                    <div className='api'>
                        {api}
                    </div>
                </div>
                <div className="name">{name}</div>

                <StandartButton className={'button-style-2'} onClick={() => {
                        if (file != undefined) {
                            downloadFile(`https://topkaproduct.com:8443/files/download/${file}`)
                        } else {
                            buyProduct();
                        }
                }}>
                    <div className='button-txt'>
                        
                        <img src={download} />
                        <span>Скачать</span>
                    </div>
                </StandartButton>
            </div>
        </>
    );
}

export default ProductVersion;