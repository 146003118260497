
import ky from 'ky';
import './LoadBanner.css'
import { useEffect } from 'react';
import { useState } from 'react';

import image from './plus.svg'
import Cookies from 'js-cookie';

function LoadBanner ({children, className, photoHash, setPhoto, isEdit = true}) {

    const [file, setFile] = useState(null);

    const handleFileSelection = async () => {
        if (!isEdit) return; 
        try {
            const input = document.createElement('input');
            input.type = 'file';

            // Listen for the file selection
            input.addEventListener('change', (event) => {
                const selectedFile = event.target.files[0];
                setFile(selectedFile);
            });

            // Trigger the file selection dialog
            input.click();
        } catch (error) {
            console.error('File selection error:', error);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            console.error('No file selected');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('token', Cookies.get('token'));
            const response = await ky.post('https://topkaproduct.com:8443/files/upload', {
                body: formData,
            }).json();

            setTimeout(() => {
                setPhoto(response.article);
            }, 1000);
            // console.log('Upload successful:', response);
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    };

    useEffect(() => {
        if (file != null) handleUpload();
    }, [file]);

    return (
        <>
            <div className={`load-banner ${className == undefined || className == null ? '' : className}`} style={photoHash != '' && photoHash != undefined && photoHash != null && isEdit ? {backgroundImage: `url(https://topkaproduct.com:8443/files/image/${photoHash})`} : {}} onClick={handleFileSelection}>
                <img className='image' src={`https://topkaproduct.com:8443/files/image/${photoHash}`} alt='banner' />
                {children != undefined && children != null &&
                <>
                {children}
                </>
                }
                {isEdit && 
                <>
                    <img className='image-plus' src={image} alt='plus'/>
                    <div className='text'>Добавить Баннер</div>
                </>
                }
                
            </div>
        </>
    );
}

export default LoadBanner;