
import { useEffect, useState } from 'react';
import triangle from './../Triangle.svg'

function OtherVideo ({linkYT}) {

    const [videoHash, setVideoHash] = useState('');

    useEffect(() => {
        if (linkYT == undefined || linkYT == '') return;
        setVideoHash(linkYT.replace('https://www.youtube.com/embed/', ''));
    }, [linkYT]);

    return (
        <>
            {!(linkYT == null || linkYT == undefined || linkYT == '' || videoHash == undefined || videoHash == '') &&
                <>
                    <iframe
                        className="other-video"
                        src={linkYT}
                        srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}
                        html,body{height:100%}
                        img,span{position:absolute;top:0;bottom:0;margin:auto}
                        span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
                        a{display:flex;align-items:center;justify-content:center;}
                        </style>
                        <a href=https://www.youtube.com/embed/${videoHash}?autoplay=1>
                        <img width=100% src=https://img.youtube.com/vi/${videoHash}/hqdefault.jpg alt='Demo video'>
                        <img width=35px src=${triangle} />
                        </a>`}
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        title="Demo video">
                    </iframe>
                </>
            }
        </>
    );
}

export default OtherVideo;