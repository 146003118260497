import StandartButton from "../../standarts/button/StandartButton";
import "./MainBlock.css"

function MainBlock({name, description, buttonText, buttonLink, image, backgroundImage}) {
return(
<>
    <div className="big-block-main" /*style={
            {
                background: `url(https://topkaproduct.com:8443/files/image/${backgroundImage}) #151515`
            }
            }*/>
        <div className="block-main-box" >

            <div className="block-left-box">
                <a className="block-name">{name}</a>
                <a className="block-description">{description} </a>

                {/* <a className="block-button-shell" href={buttonLink}>
                    <div className="block-button">
                        <div className="button-text">{buttonText}</div>
                    </div>
                </a> */}
                <StandartButton className={'button-style-3'} onClick={() => window.location.href = `${buttonLink}`}>{buttonText}</StandartButton>

                {/* <div className="block-with-image">
                    <img className="block-image" src={`../img/block/${miniImageName}`}/>
                </div> */}
            </div>

            <div className="block-right-box">
                <div className="block-with-image">
                    <img className="block-image" src={`https://topkaproduct.com:8443/files/image/${image}`}/>
                </div>
            </div>
        </div>

    </div>
</>
);

}

export default MainBlock;
