import Feedback from "../../feedback/Feedback";
import Title from "../../title/Title";
import "./FeedbackBlock.css";
import { Swiper, SwiperSlide } from "swiper/react";

export default function FeedBackBlock() {
  const min_gap = 11;
  const max_gap = 20;
  const min_desktop_width_not_full2 = 800;
  const max_desktop_width = 1920;

  return (
    <>
      <Title title={"Нас рекомендуют"} style={{ background: `#151515` }} />
      <div className="feedback-block">
        <Swiper
          slidesPerView={3}
          spaceBetween={
            min_gap +
            ((max_gap - min_gap) *
              (window.screen.width - min_desktop_width_not_full2)) /
              (max_desktop_width - min_desktop_width_not_full2)
          }
          autoplay={{
            delay: 7500,
            disableOnInteraction: true,
          }}
          className="pc-swiper"
          allowTouchMove={true}
        >
          <SwiperSlide>
            {" "}
            <Feedback
              name="E_X_P_A_N"
              text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
            />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Feedback
              name="E_X_P_A_N"
              text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
            />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Feedback
              name="E_X_P_A_N"
              text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
            />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Feedback
              name="E_X_P_A_N"
              text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
            />{" "}
          </SwiperSlide>
        </Swiper>

        <Swiper
          slidesPerView={"auto"}
          spaceBetween={15}
          autoplay={{
            delay: 7500,
            disableOnInteraction: true,
          }}
          className="mobile-swiper"
          allowTouchMove={true}
        >
          <SwiperSlide>
            {" "}
            <Feedback
              name="E_X_P_A_N"
              text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
            />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Feedback
              name="E_X_P_A_N"
              text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
            />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Feedback
              name="E_X_P_A_N"
              text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
            />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <Feedback
              name="E_X_P_A_N"
              text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
            />{" "}
          </SwiperSlide>
        </Swiper>
      </div>

      {/* <div className="feedback-block">
        <Feedback
          name="E_X_P_A_N"
          text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
        />
        <Feedback
          name="E_X_P_A_N"
          text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
        />
        <Feedback
          name="E_X_P_A_N"
          text="Лучшее что видело человечество, хорошие ребята. Классные парни, люблю их хочень, сам их хочу."
        />
      </div> */}
    </>
  );
}
