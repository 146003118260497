import DynamicImage from "../header/DynamicImage";
import StandartButton from "../standarts/button/StandartButton";
import "./PaidProduct.css";

function PaidProduct({ productId, className, style, icon, name, description }) {
  const goProduct = () => {
    window.location.href = `/product/${productId}`;
  };

  return (
    <>
      <div
        className={
          className == undefined
            ? "paid-product-main-box"
            : `paid-product-main-box ${className}`
        }
        style={style}
      >
        <div className="zone-for-tags">
          <div className="tags">
            <div className="tag">Хит</div>
            <div className="tag">Выгодно</div>
            <div className="tag">-21%</div>
          </div>

          <div className="global-zone">
            <div className="icon-shell">
              <div className="icon-zone">
                <DynamicImage mode={2} name={icon} className="icon" />
              </div>
              <div className="price">699₽</div>
            </div>

            <div className="info-zone">
              <div className="name">{name}</div>
              <div className="description">{description}</div>
            </div>
            <div className="button-zone">
              <div className="price">699₽</div>
              <StandartButton className={"button-style-4"} onClick={goProduct}>
                Купить
              </StandartButton>
            </div>
          </div>
        </div>
        {/* <div className='global-zone'>
                    <div className='icon-zone'>
                        <DynamicImage mode={2} name={icon} className='icon' /> 
                    </div>
                    <div className='info-zone'>
                        <div className='name'>{name}</div>
                        <div className='description'>{description}</div>
                    </div>
                </div>
                <div className="block-button-shell" onClick={goProduct}>
                    <div className="block-button">
                        <div className="button-text">Приобрести</div>
                    </div>
                </div> */}
      </div>
    </>
  );
}

export default PaidProduct;
