import "./header.css"

import { TabMenu } from 'primereact/tabmenu';
import DynamicImage from "./DynamicImage";

import Logo from './logo.svg'
import HeaderPhone from "./HeaderPhone";
import { click } from "@testing-library/user-event/dist/click";
import Cookies from "js-cookie";

function Header({headerIndex, setHeaderIndex}) {

    const items = [
        {
            label: <span className="green">Магазин</span>, 
            icon: <DynamicImage name='shop_icon' folder='header'/>,
            command:(e) => {
                clickText(0);
            },
        },
        {
            label: <>Моды <span className="orange">[Free]</span></>, 
            icon: <DynamicImage name='free_mods_icon' folder='header' />,
            command:(e) => {
                clickText(1);
            },
        },
        {
            label: 'Отзывы', 
            icon: <DynamicImage name='feedback_icon' folder='header'/>,
            command:(e) => {
                clickText(2);
            },
        },
        {
            label: 'Группа вк', 
            icon: <DynamicImage name='vk_icon' folder='header' />,
            command:(e) => {
                clickText(3);
            },
        },
        {
            label: <span className="orange">Личный кабинет</span>, 
            icon: <DynamicImage name='personal_account_icon' folder='header' />,
            command:(e) => {
                clickText(4);
            }
        }
    ];

    function scrollToElement(id) {
        // Найдите элемент с заданным id
        const element = document.getElementById(id);
      
        if (element) {
          // Определите начальное положение скролла
          const startPosition = window.pageYOffset;
          // Определите конечное положение скролла
          const targetPosition = element.offsetTop;
          // Определите расстояние, на которое нужно прокрутить страницу
          const distance = targetPosition - startPosition;
          // Определите время анимации (в миллисекундах)
          const duration = 1000; // Например, 1000 мс (1 секунда)
      
          // Определите начальное время анимации
          let startTime = null;
      
          // Создайте функцию анимации
          function scrollAnimation(currentTime) {
            if (!startTime) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            // Вычислите новую позицию скролла
            const newPosition = easeInOutQuad(timeElapsed, startPosition, distance, duration);
            window.scrollTo(0, newPosition);
      
            if (timeElapsed < duration) {
              requestAnimationFrame(scrollAnimation);
            }
          }
      
          // Функция для плавной анимации (easeInOutQuad)
          function easeInOutQuad(t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
          }
      
          // Запустите анимацию
          requestAnimationFrame(scrollAnimation);
        }
      }

    function clickText(index) {
        setTimeout(() => {
            switch (index) {
                case 0:
                    scrollToElement('paid-product');
                    window.location.href = '/#paid-product';
                    break;
    
                case 1:
                    scrollToElement('free-product');
                    window.location.href = '/#paid-product';
                    break;
    
                case 2:
                    // window.location.href = '/cards';
                    window.open('https://vk.com/topic-193648337_49690663', '_blank');
                    break;
    
                case 3:
                    window.open('https://vk.com/mstresurs', '_blank');
                    break;
    
                case 4:
                    const token = Cookies.get('token');

                    if (token == undefined) {
                        window.location.href = 'https://oauth.vk.com/authorize?client_id=51782073&display=page&redirect_uri=https://topkaproduct.com/join&scope=262148&response_type=code&v=5.131';
                    } else {
                        window.location.href = '/profile';
                    }
                    break;
            }
        }, 200);
    }

    function goToStart() {
        window.location.href = '';
    }

  return (
    <>
    {items &&
    <header>
        <div className="header-main-box">
            <a className="header-main-up" href="/">
                <img src={Logo} 
                    className="header-main-logo"
                    alt="Logo"
                    // onClick={goToStart}
                    />
            </a>

            <div className="header-main-down">
                {/* <div className="header-nav-bar">Магазин</div>
                <div className="header-nav-bar">Моды [Free]</div>
                <div className="header-nav-bar">Отзывы</div>
                <div className="header-nav-bar">Группа вк</div>
                <div className="header-nav-bar">Личный кабинет</div> */}

                <div className="header-nav-bar">
                    <div className="card">
                        <TabMenu model={items} activeIndex={headerIndex} />
                    </div>
                </div>
            </div>
            <div className="header-main-down-phone">
                <HeaderPhone headerIndex={headerIndex} clickText={clickText}/>
            </div>
        </div>
        
    </header>
    }
    </>
  );
}

export default Header;
