
import { useEffect } from 'react';
import './MiniCarousel.css'
import { useState } from 'react';
import { Children } from 'react';
import { cloneElement } from 'react';
import DynamicImage from '../../header/DynamicImage';
import React from 'react';

export const MiniCarousel = ({children}) => {

    const [pages, setPages] = useState([]);

    const [index, setIndex] = useState(0);
    const [offset, setOffset] = useState(0);

    const [minDesktopWidth] = useState(800);
    const [maxDesktopWidth] = useState(1920);

    const [minProductBoxCenteredWidth] = useState(199);
    const [maxProductBoxCenteredWidth] = useState(414);

    const [minProductBoxWidth] = useState(159.39);
    const [maxProductBoxWidth] = useState(330);

    const [minProductMarginLeft] = useState(11.11);
    const [maxProductMarginLeft] = useState(22);

    const onClickLeft = () => {
        if (index - 3 >= (0)) {
            setIndex(index - 3);
        } else if (index - 2 >= (0)) {
            setIndex(index - 2);
        } else if (index - 1 >= (0)) {
            setIndex(index - 1);
        } else {
            if (children.length >= 3) {
                setIndex(children.length-3);
            }
        }
    }
    
    const onClickRight = () => {
        if (index + 3 < (children.length-1)) {
            setIndex(index + 3);
        } else if (index + 2 < (children.length-2)) {
            setIndex(index + 2);
        } else if (index + 1 < (children.length-2)) {
            setIndex(index + 1);
        } else {
            setIndex(0);
        }
    }

    useEffect(() => {

        let actualWidth = minProductBoxWidth + (maxProductBoxWidth - minProductBoxWidth) * (window.innerWidth - minDesktopWidth) / (maxDesktopWidth - minDesktopWidth);
        let actualMargin = minProductMarginLeft + (maxProductMarginLeft - minProductMarginLeft) * (window.innerWidth - minDesktopWidth) / (maxDesktopWidth - minDesktopWidth);

        setOffset((-(index/3) * (actualWidth + actualWidth + actualWidth + (actualMargin * 3))));
        
        let centerElement = Math.abs(index) + 1;
        setPages(Children.map(children, (child, i) => {
            let clname = i == centerElement ? 'centered' : ''; //if element in center, need up
            return React.cloneElement(
                child, { 
                    className: clname
                });
        }));
    }, [index]);
    
    useEffect(() => {
        let a = 0;
        setPages(Children.map(children, child => {
            a++;
            let clname = a == 2 ? 'centered' : '';
            return React.cloneElement(
                child, { 
                    className: clname 
                });
        }));
    }, [children]);

    function handleResize() {
        let actualWidth = minProductBoxWidth + (maxProductBoxWidth - minProductBoxWidth) * (window.innerWidth - minDesktopWidth) / (maxDesktopWidth - minDesktopWidth);
        let actualMargin = minProductMarginLeft + (maxProductMarginLeft - minProductMarginLeft) * (window.innerWidth - minDesktopWidth) / (maxDesktopWidth - minDesktopWidth);

        setOffset((-(index/3) * (actualWidth + actualWidth + actualWidth + (actualMargin * 3))));
    }

    window.addEventListener("resize", handleResize);

    return (
        <div className="main-container-mini">
            <DynamicImage name='left-arrow' folder='carousel' className='button-arrow left' onClick={onClickLeft}/>
            <div className="window">
                <div className="all-pages-container"
                style={{
                    transform: `translateX(${offset}px)`
                }}>
                    {pages}
                </div>
            </div>
            <DynamicImage name='right-arrow' folder='carousel' className='button-arrow right' onClick={onClickRight}/>
        </div>
    );

}