import "./header.css"

import DynamicImage from "./DynamicImage";

import Logo from './logo.svg'
import BackArrow from './back_arrow.svg'
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

function AdminHeader({ headerIndex, setHeaderIndex, backArrow, backHref }) {

    const [href, setHref] = useState(backHref);

    function goToStart() {
        window.location.href = '';
    }

    useEffect(() => {

        setHref(backHref == undefined ? `/admin` : backHref);
        // console.log(backHref)
    }, []);

    return (
        <>

            <header>
                <div className="header-main-box">
                    <a className="header-main-up" href="/">
                        <img src={Logo}
                            className="header-main-logo"
                            alt="Logo"
                        // onClick={goToStart}
                        />
                    </a>

                    <div className="header-main-down">
                        {/* <div className="header-nav-bar">Магазин</div>
                <div className="header-nav-bar">Моды [Free]</div>
                <div className="header-nav-bar">Отзывы</div>
                <div className="header-nav-bar">Группа вк</div>
                <div className="header-nav-bar">Личный кабинет</div> */}

                        <div className="header-nav-bar">
                            <div className="admin-panel">
                                {backArrow && href != undefined && 
                                    <a className="arrow-back-box" href={href}>
                                        <img src={BackArrow}
                                            className="img-arrow"
                                            alt="Logo"/>
                                        <div className="text-back">Назад</div>
                                    </a>
                                }
                                <div className="admin-panel-text">Админ панель</div>

                            </div>
                        </div>
                    </div>

                </div>

            </header>

        </>
    );
}

export default AdminHeader;
