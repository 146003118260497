import { useParams } from "react-router";
import LoadBanner from "../../standarts/load-banner/LoadBanner";
import { useEffect, useState } from "react";
import { Image } from "primereact/image";
import ky from "ky";
import { v4 as uuidv4 } from "uuid";

import "./ProductPage.css";
import Cookies from "js-cookie";
import ProductVersion from "../../standarts/product-version/ProductVersion";

import triangle from "./Triangle.svg";
import download_icon from "./download_icon.svg";
import last_update_icon from "./last_update_icon.svg";
import view_icon from "./view_icon.svg";
import StandartButton from "../../standarts/button/StandartButton";

import zoom_icon from "./zoom_icon.svg";
import CarouselPictures from "../../standarts/carousel-pictures/CarouselPictures";
import { Swiper, SwiperSlide } from "swiper/react";
import OtherProduct from "../../standarts/other-product/OtherProduct";
import OtherVideo from "./video/OtherVideo";
import FeedBackUser from "./feedback/FeedBackUser";

function ProductPage({ openDialogSubscribe }) {
  const { productId } = useParams();

  const [products, setProducts] = useState(null);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [linkYT, setLinkYT] = useState("");
  const [videoHash, setVideoHash] = useState("");
  const [price, setPrice] = useState("");
  const [banner_image, setBannerImage] = useState("");
  const [svg_mini_icon, setSvgMiniIcon] = useState("");

  const [paid, setPaid] = useState("");
  const [discount_if_have_old, setDiscountIfHaveOld] = useState("");
  const [old_version, setOldVersion] = useState("");

  const [images, setImages] = useState(undefined);
  const [renderImages, setRenderImages] = useState(undefined);

  const [versions, setVersions] = useState(null);
  const [renderVersions, setRenderVersions] = useState(0);

  useEffect(() => {
    if (images == null || images == undefined) return;
    setRenderImages(
      images.map((e) => {
        return (
          <div key={uuidv4()}>
            {/* <img src={``} alt="image" /> */}
            <Image
              src={`https://topkaproduct.com:8443/files/image/${e}`}
              indicatorIcon={
                <>
                  {" "}
                  <img src={zoom_icon} />{" "}
                </>
              }
              alt="Image"
              preview
              className="picture"
            />
          </div>
        );
      })
    );
  }, [images]);

  useEffect(() => {
    if (
      versions == null ||
      versions == undefined ||
      svg_mini_icon == "" ||
      svg_mini_icon == null ||
      svg_mini_icon == undefined
    )
      return;
    setRenderVersions(
      versions.map((e) => {
        return (
          <div key={uuidv4()}>
            {/* <img src={``} alt="image" /> */}
            <SwiperSlide>
              <ProductVersion
                id={e.id}
                name={e.title}
                svg_mini_icon={svg_mini_icon}
                api={e.api}
                file={e.file}
                openDialogSubscribe={openDialogSubscribe}
              />
            </SwiperSlide>
          </div>
        );
      })
    );
  }, [versions, svg_mini_icon]);

  useEffect(() => {
    async function fetchData() {
      const formData = new FormData();
      formData.append("id", productId);
      formData.append("token", Cookies.get("token"));
      const response = await ky
        .post("https://topkaproduct.com:8443/product/getProductVersions", {
          body: formData,
        })
        .json();

      if (response.status === "error") {
        // console.log(response)
        return;
      }

      setVersions(response.versions);
      // console.log(response.versions)
      // console.log(response)
    }
    fetchData().then((r) => r);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const formData = new FormData();
      formData.append("token", Cookies.get("token"));
      const response = await ky
        .post("https://topkaproduct.com:8443/product/getProducts", {
          body: formData,
        })
        .json();

      if (response.status === "error") {
        // console.log(response)
        return;
      }

      setProducts(response.products);
    }
    fetchData().then((r) => r);
  }, []);

  useEffect(() => {
    if (products == null) return;
    setName(products[productId - 1].name);
    setDescription(products[productId - 1].description);
    setLinkYT(products[productId - 1].link_yt);
    setVideoHash(
      products[productId - 1].link_yt.replace(
        "https://www.youtube.com/embed/",
        ""
      )
    );
    setPrice(products[productId - 1].price);
    setBannerImage(products[productId - 1].banner_image);
    setSvgMiniIcon(products[productId - 1].svg_mini_icon);

    setImages(products[productId - 1].images);
    setPaid(products[productId - 1].paid);
    setDiscountIfHaveOld(products[productId - 1].discount_if_have_old);
    setOldVersion(products[productId - 1].old_version);
  }, [products]);

  return (
    <>
      <div className="product-page">
        <LoadBanner className="banner" isEdit={false} photoHash={banner_image}>
          {/* <div className="title-image">
                        {name}
                        {paid &&
                            <div>
                                <span className="price">{price}₽</span>
                            </div>
                        }
                    </div> */}
        </LoadBanner>

        <div className="title-box">
          <div className="title">
            {name} <span className="ver">2</span>
          </div>

          <div className="info-box">
            {!paid && (
              <>
                <div className="info">
                  <img className="img" src={download_icon} />
                  <div className="text main">14.231</div>
                  <div className="text additional">скачиваний</div>
                </div>
              </>
            )}

            {paid && (
              <>
                <div className="info extend">
                  <img className="img" src={last_update_icon} />
                  <div className="text main">14.231</div>
                  <div className="text additional">последнее обновление</div>
                </div>
              </>
            )}

            <div className="info">
              <img className="img" src={view_icon} />
              <div className="text main">17.231</div>
              <div className="text additional">просмотров</div>
            </div>
          </div>
        </div>

        <div className="content-box">
          <div className="main-info-box">
            <div className="title-box2">
              <div className="icon">
                <img
                  className="mini-icon"
                  src={`https://topkaproduct.com:8443/files/image/${svg_mini_icon}`}
                  alt="mini icon"
                />
              </div>

              <div className="title">
                Vvavav av av av avava va asd asd asd asd asd asd asd asd asd asd
              </div>
            </div>

            <div className="desc">
              При помощи этой модификации вы можете менять цвет, толщину линий
              хитбокса игроков и мобов. Модификация разрешена на всех серверах и
              за неё вы не получите наказание.
            </div>
            {paid && (
              <>
                <div className="button-box-with-price">
                  <StandartButton className={"button-style-3"}>
                    Купить
                  </StandartButton>
                  <div className="price-box">
                    <span className="price old">199₽</span>
                    <span className="price">399₽</span>
                  </div>
                </div>
              </>
            )}

            {!paid && (
              <>
                <StandartButton className={"button-style-1"}>
                  Бесплатно
                </StandartButton>
              </>
            )}
          </div>

          <div className="media-box">
            {!(linkYT == null || linkYT == undefined || linkYT == "") && (
              <iframe
                className="video"
                src={linkYT}
                srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}
                                html,body{height:100%}
                                img,span{position:absolute;top:0;bottom:0;margin:auto}
                                span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
                                a{display:flex;align-items:center;justify-content:center;}
                                </style>
                                <a href=https://www.youtube.com/embed/${videoHash}?autoplay=1>
                                <img width=100% src=https://img.youtube.com/vi/${videoHash}/hqdefault.jpg alt='Demo video'>
                                <img width=35px src=${triangle} />
                                </a>`}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Demo video"
              ></iframe>
            )}

            <CarouselPictures>{renderImages}</CarouselPictures>
          </div>
        </div>
        {!paid && (
          <>
            <div className="download-box">
              <div className="block-title">Скачать</div>

              <div className="versions">
                <Swiper
                  slidesPerView={5}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="pc-swiper"
                >
                  {renderVersions}
                </Swiper>

                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={`15`}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="mobile-swiper"
                >
                  {renderVersions}
                </Swiper>
              </div>
            </div>

            <div className="other-products">
              <div className="block-title">Другие модификации</div>

              <div className="products">
                <Swiper
                  slidesPerView={3}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="pc-swiper"
                >
                  <SwiperSlide>
                    {" "}
                    <OtherProduct
                      id={0}
                      name={"boobies v1"}
                      desc={"zxc zxc 1337 228"}
                      svg_mini_icon={svg_mini_icon}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherProduct
                      id={1}
                      name={"boobies v2"}
                      desc={"zxc zxc 1337 228"}
                      svg_mini_icon={svg_mini_icon}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherProduct
                      id={2}
                      name={"boobies v3"}
                      desc={"zxc zxc 1337 228"}
                      svg_mini_icon={svg_mini_icon}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherProduct
                      id={0}
                      name={"boobies v1"}
                      desc={"zxc zxc 1337 228"}
                      svg_mini_icon={svg_mini_icon}
                    />{" "}
                  </SwiperSlide>
                </Swiper>

                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={`15`}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="mobile-swiper"
                >
                  <SwiperSlide>
                    {" "}
                    <OtherProduct
                      id={0}
                      name={"boobies v1"}
                      desc={"zxc zxc 1337 228"}
                      svg_mini_icon={svg_mini_icon}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherProduct
                      id={1}
                      name={"boobies v2"}
                      desc={"zxc zxc 1337 228"}
                      svg_mini_icon={svg_mini_icon}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherProduct
                      id={2}
                      name={"boobies v3"}
                      desc={"zxc zxc 1337 228"}
                      svg_mini_icon={svg_mini_icon}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherProduct
                      id={0}
                      name={"boobies v1"}
                      desc={"zxc zxc 1337 228"}
                      svg_mini_icon={svg_mini_icon}
                    />{" "}
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </>
        )}

        {paid && (
          <>
            <div className="other-videos">
              <div className="block-title">Видеоролики с этой модификацией</div>

              <div className="videos">
                <Swiper
                  slidesPerView={3}
                  autoplay={{
                    delay: 7500,
                    disableOnInteraction: false,
                  }}
                  className="pc-swiper"
                  allowTouchMove={false}
                >
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/dBixUF-9-Pg"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/nZBh9w7C1P4"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/1NT6GLLOY7Y"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/LGHCB5VUeOU"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/39kXir0bQU0"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/3c584TGG7jQ"}
                    />{" "}
                  </SwiperSlide>
                </Swiper>

                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={`15`}
                  autoplay={{
                    delay: 7500,
                    disableOnInteraction: false,
                  }}
                  className="mobile-swiper"
                  allowTouchMove={false}
                >
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/dBixUF-9-Pg"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/nZBh9w7C1P4"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/1NT6GLLOY7Y"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/LGHCB5VUeOU"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/39kXir0bQU0"}
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <OtherVideo
                      linkYT={"https://www.youtube.com/embed/3c584TGG7jQ"}
                    />{" "}
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>

            <div className="feedbacks-block">
              <div className="block-title">Отзывы наших клиентов</div>

              <div className="feedbacks">
                <Swiper
                  slidesPerView={3}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="pc-swiper"
                >
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={0}
                      name={"я андрей"}
                      text={"я андрей"}
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={1}
                      name={"Юрий Швед"}
                      text={
                        "Шикарная модификация, лучшая в своём роде. Наслаждаюсь каждой минутой, в которой присутсвует этот мод. Не думал что кто-то сможет реализовать это настолько качественно, но они смогли. Играю с модом уже больше..."
                      }
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={2}
                      name={"ПАВ АВ АВ ПВПВПВА"}
                      text={
                        "Вот так вот хуяришь Гряволом 40 лет на заводе, с утра до ночи, от звонка до звонка, еле еле собираешь себе на самую захудалую Ладу Доминатор, выезжаешь на босса и видишь молодого ебаного виспа на Ламборгини и понимаешь насколько ебаная жизнь"
                      }
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={3}
                      name={"ТЫЩЫ ТЫЩ ТЫЩ ТЩЫТЩ"}
                      text={
                        "илюх научись на фейке играть и птсы вверх пойдут отвечаю"
                      }
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={4}
                      name={"ТОФШЫВТОЛФЫВ ФЫЛВФЫВЛЛЩФЛФ"}
                      text={
                        "( ° ͜ʖ͡°)╭∩╮ Эй, ансабы, как вы там? Кушаете рекламу небось roflanEbalo ?( ° ͜ʖ͡°)╭∩╮"
                      }
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={5}
                      name={"Друг ярика"}
                      text={`Играем с Яриком в шахматы
Один ход и он поставит мне мат
Я говорю: Ярик, чья собака по улице бежит? 
Ярик: Ничья
Я: С О Г Л А С Е Н`}
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                </Swiper>

                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={`15`}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  className="mobile-swiper"
                >
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={0}
                      name={"я андрей"}
                      text={"я андрей"}
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={1}
                      name={"Юрий Швед"}
                      text={
                        "Шикарная модификация, лучшая в своём роде. Наслаждаюсь каждой минутой, в которой присутсвует этот мод. Не думал что кто-то сможет реализовать это настолько качественно, но они смогли. Играю с модом уже больше..."
                      }
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={2}
                      name={"ПАВ АВ АВ ПВПВПВА"}
                      text={
                        "Вот так вот хуяришь Гряволом 40 лет на заводе, с утра до ночи, от звонка до звонка, еле еле собираешь себе на самую захудалую Ладу Доминатор, выезжаешь на босса и видишь молодого ебаного виспа на Ламборгини и понимаешь насколько ебаная жизнь"
                      }
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={3}
                      name={"ТЫЩЫ ТЫЩ ТЫЩ ТЩЫТЩ"}
                      text={
                        "илюх научись на фейке играть и птсы вверх пойдут отвечаю"
                      }
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={4}
                      name={"ТОФШЫВТОЛФЫВ ФЫЛВФЫВЛЛЩФЛФ"}
                      text={
                        "( ° ͜ʖ͡°)╭∩╮ Эй, ансабы, как вы там? Кушаете рекламу небось roflanEbalo ?( ° ͜ʖ͡°)╭∩╮"
                      }
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <FeedBackUser
                      date={"12 Января 2024"}
                      rating={5}
                      name={"Друг ярика"}
                      text={`Играем с Яриком в шахматы
Один ход и он поставит мне мат
Я говорю: Ярик, чья собака по улице бежит? 
Ярик: Ничья
Я: С О Г Л А С Е Н`}
                      photo_hash={
                        "gEAGou0ezWQ3KhRLqhCPG9vEb9Yb83TgXkgW06rKsEU1xQiGDqwVr1RL73RF1OW8lwkwSzpTFsmShfyD5dLnx8A8PCGYkTKoaUNaijFFXznSsuNEQxvlkTEyxGP5BekDB3meMNzJEiAxd92Cvzdw0NuwnlhKHJqAR53IejSOWt9xTPuYISNnOlY12AQIF9ZM0IcDCXQ03Vk57bwhZiwIJVJMk52w2lnf1wdtNXWLlrCUe5k7oxyYWazPkXSURMK"
                      }
                    />{" "}
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </>
        )}
        {/* <div className="information">

                    <div className="media">
                        {!(linkYT == null || linkYT == undefined || linkYT == '') &&
                            <iframe //TODO: edit triangle on img
                                className="video"
                                src={linkYT}
                                srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}
                                html,body{height:100%}
                                img,span{position:absolute;top:0;bottom:0;margin:auto}
                                span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}
                                a{display:flex;align-items:center;justify-content:center;}
                                </style>
                                <a href=https://www.youtube.com/embed/${videoHash}?autoplay=1>
                                <img width=100% src=https://img.youtube.com/vi/${videoHash}/hqdefault.jpg alt='Demo video'>
                                <img width=35px src=${triangle} />
                                </a>`}
                                frameborder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                title="Demo video">
                            </iframe>
                        }
                        {renderImages}
                    </div>

                    <div className="info-box">
                        <div className="title">Описание</div>
                        <div className="description">{description}</div>
                    </div>

                    

                </div>
                <div className="versions">
                    <div className="title">Скачать</div>
                    <div className="versions-box">
                        {renderVersions}
                    </div>
                </div> */}
      </div>
    </>
  );
}

export default ProductPage;
