import { useEffect, useState } from "react";
import DynamicImage from "./DynamicImage";

import './HeaderPhone.css'
import { TabMenu } from "primereact/tabmenu";

function HeaderPhone({headerIndex, clickText}) {

    const [open, setOpen] = useState(false);
    
    const items = [
        {
            label: 'Магазин', 
            command:(e) => {
                setOpen(false);
                clickText(0);
            },
        },
        {
            label: <>Моды <span className="orange">[Free]</span></>, 
            command:(e) => {
                setOpen(false);
                clickText(1);
            },
        },
        {
            label: 'Отзывы', 
            command:(e) => {
                setOpen(false);
                clickText(2);
            },
        },
        {
            label: 'Группа вк', 
            command:(e) => {
                setOpen(false);
                clickText(3);
            },
        },
        {
            label: 'Личный кабинет', 
            command:(e) => {
                setOpen(false);
                clickText(4);
            }
        }
    ];

    function onClickIcon() {
        setOpen(!open);
        window.scrollBy(0, -99999);
    }

    useEffect(() => {
        document.body.style.overflow = open ? 'hidden' : '';
    }, [open]);


    return (
        <>
            <div className="header-phone">
                <DynamicImage folder='header' name='phone_nav_menu_icon' onClick={onClickIcon} className={open ? 'icon open' : 'icon'}/> 
            </div>
            
            <div className="header-nav-menu-shell" style={
                !open ? ({
                transform: `translateX(-130%)`

            }) : {transform: `translateX(0px)`}}>
                <div className="nav-menu">
                    <TabMenu model={items} activeIndex={headerIndex} />
                </div>
            </div>
            
        </>
    );

}

export default HeaderPhone;