
import { useEffect, useState } from 'react';
import ky from 'ky';
import Cookies from 'js-cookie';
import MainPage from './MainPage';

function Join() {

    useEffect(() => {
        const url = window.location.href;
        const urlSearchParams = new URLSearchParams(url);

        const codeParam = urlSearchParams.get('https://topkaproduct.com/join?code');
        if (codeParam != null) {
            async function fetchData() {
                const formData = new FormData();
                formData.append('code', codeParam);

                const response = (await ky.post(`https://topkaproduct.com:8443/user/reg`, {
                    body: formData
                }).json());
                
                if (response.status == 'good') {
                    Cookies.set('token', response.token, { expires: 365 * 100 });
                    window.location.href = '/profile';
                }
            }
            fetchData().then(r => r)
        }
    }, []);

    return (
        <>
            <MainPage />
        </>);
}

export default Join;