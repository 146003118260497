import ky from "ky";
import AdminAboutBlock from "../../blocks/admin-about-block/AdminAboutBlock";
import AdminCarouselBlock from "../../blocks/admin-carousel-block/AdminCarouselBlock";
import './AdminPage.css'
import { useEffect } from "react";
import { useState } from "react";
import AdminPaidProductBlock from "../../blocks/admin-paid-product-block/AdminPaidProductBlock";
import AdminFreeProductBlock from "../../blocks/admin-free-product-block/AdminFreeProductBlock";
import Cookies from "js-cookie";

function AdminPage() {

  const [mainPageJson, setMainPageJson] = useState(null);

  const [products, setProducts] = useState(null);
  const [updateProducts, setUpdateProducts] = useState(1);

  const addNew = async (paid) => {
    try {
      const formData = new FormData();
      formData.append('paid', paid);
      formData.append('token', Cookies.get('token'));

      const response = await ky.post('https://topkaproduct.com:8443/product/createProduct', {
        body: formData,
      }).json();

      setUpdateProducts(updateProducts + 1);

    } catch (error) {
      // console.error('Upload failed:', error);
    }
  }

  useEffect(() => {
    async function fetchData() {

      const response = (await ky.post('https://topkaproduct.com:8443/mainpage/getAboutInfo').json());
      
      if (response.status === "error") {
        // console.log(response)
        return;
      }

      setMainPageJson(response);
    }
    fetchData().then(r => r)
  }, []);

  useEffect(() => {
    async function fetchData() {
      const formData = new FormData();
      formData.append('token', Cookies.get('token'));
      const response = (await ky.post('https://topkaproduct.com:8443/product/getProducts', {
        body: formData,
    }).json());

      if (response.status === "error") {
        // console.log(response)
        return;
      }

      setProducts(response.products);
    }
    fetchData().then(r => r)
  }, [updateProducts]);

  return (
    <div className="admin-page-body">
      {mainPageJson != null &&
        <>
          <AdminCarouselBlock />
          <AdminAboutBlock mainPageJson={mainPageJson} />
          <AdminPaidProductBlock mainPageJson={mainPageJson} products={products} addNew={addNew} />
          <AdminFreeProductBlock mainPageJson={mainPageJson} products={products} addNew={addNew} />

        </>
      }
    </div>
  );
}

export default AdminPage;