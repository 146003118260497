
import { useState } from 'react';
import './LoadImagesBox.css'
import image from './plus.svg'
import { useEffect } from 'react';
import ky from 'ky';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

function LoadImagesBox ({photoHashs, setPhotoHashs}) {

    const [renderImages, setRenderImages] = useState(null);
    const [file, setFile] = useState(null);

    useEffect(() => {
        if (photoHashs == undefined) return;
        setRenderImages(photoHashs.map((e, j) => {
            return (
                <span key={uuidv4()} className='loaded-image'>
                    <img key={uuidv4()} className='uploaded-image' src={`https://topkaproduct.com:8443/files/image/${e}`} alt='image' />
                    <div className='remove-image' onClick={() => removeImage(j)} >
                        X
                    </div>
                </span>
            );
        }));
    }, [photoHashs]);

    const removeImage = (j) => {
        // console.log('remove')
        var array = [...photoHashs]; // copy
        array.splice(j, 1);
        setPhotoHashs(array);
    }

    const handleFileSelection = async () => {
        try {
            const input = document.createElement('input');
            input.type = 'file';

            // Listen for the file selection
            input.addEventListener('change', (event) => {
                const selectedFile = event.target.files[0];
                setFile(selectedFile);
            });

            // Trigger the file selection dialog
            input.click();
        } catch (error) {
            console.error('File selection error:', error);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            console.error('No file selected');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('token', Cookies.get('token'));
            const response = await ky.post('https://topkaproduct.com:8443/files/upload', {
                body: formData,
            }).json();

            setTimeout(() => {
                // photoHashs[]
                setPhotoHashs(photoHashs => [...photoHashs, response.article]);
            }, 1000);
            // console.log('Upload successful:', response);
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    };

    useEffect(() => {
        if (file != null) handleUpload();
    }, [file]);

    return (
        <>
        <div className='load-images'>
            {renderImages != null && <>{renderImages}</>}
            
            <div className='upload-box' onClick={handleFileSelection}>
                <img src={image} alt='plus'/>
            </div>
        </div>
        </>
    );
}

export default LoadImagesBox;