
import { useEffect, useState } from 'react';
import AdminSettingsAddBlock from '../admin-settings-block/AdminSettingsAddBlock';
import AdminSettingsBlock from '../admin-settings-block/AdminSettingsBlock';
import './AdminCarouselBlock.css'
import { v4 as uuidv4 } from 'uuid';
import ky from 'ky';
import Cookies from 'js-cookie';

function AdminCarouselBlock() {

    const [renderCarousels, setRenderCarousels] = useState(null);
    const [updateCarousels, setUpdateCarousels] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const formData = new FormData();
            formData.append('token', Cookies.get('token'));
            const response = await ky.post('https://topkaproduct.com:8443/carousel/getCarousels', {
                body: formData,
            }).json();

            if (response.status === "error") {
                // console.log(response)
                return;
            }

            setRenderCarousels(response.carousels.map((e) => {
                return (
                    <AdminSettingsBlock name={e.title} onClick={() => {window.location.href = `/admin/editCarousel/${e.id}`}}/>
                );
            }));

            // setVersions(response.versions);
            // console.log(response.versions)
            // console.log(response)
        }
        fetchData().then(r => r)
    }, [updateCarousels]);

    const addCarousel = async () => {
        try {
            const formData = new FormData(); //TODO: add token
            formData.append('id', 5);
            formData.append('token', Cookies.get('token'));

            const response = await ky.post('https://topkaproduct.com:8443/carousel/createCarousel', {
                body: formData,
            }).json();
            
            // console.log(response)
            setUpdateCarousels(updateCarousels+1);
            // console.log('Upload successful:', response);
        } catch (error) {
            // console.error('Upload failed:', error);
        }
        
    }

    return(
    <>
        <div className='admin-carousel'>
            <div className='main-box'>

                <div className='admin-title-box'>
                    <div className='green-box' />
                    <div className='text'>Первый блок</div>
                </div>

                <div className='mini-title'>Подблоки</div>

                <div className='carousle-blocks'>
                {/* <AdminSettingsBlock name='text1' onClick={() => {console.log('settings click test');}}/>
                <AdminSettingsBlock name='text2' onClick={() => {console.log('settings click test');}}/>
                <AdminSettingsBlock name='text3' onClick={() => {console.log('settings click test');}}/>
                <AdminSettingsBlock name='text4' onClick={() => {console.log('settings click test');}}/>
                <AdminSettingsBlock name='text5' onClick={() => {console.log('settings click test');}}/>
                <AdminSettingsBlock name='text6' onClick={() => {console.log('settings click test');}}/>
                <AdminSettingsBlock name='text7' onClick={() => {console.log('settings click test');}}/>
                <AdminSettingsBlock name='text8 ' onClick={() => {console.log('settings click test');}}/> */}
                {renderCarousels}
                <AdminSettingsAddBlock name='Добавить подблок' onClick={addCarousel}/>
                </div>
                

            </div>
        </div>
    </>
    );
}

export default AdminCarouselBlock;