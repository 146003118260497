
import { useEffect, useState } from 'react';
import StandartButton from '../button/StandartButton';
import './AddVersion.css'
import image from './plus.svg'
import ky from 'ky';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

function AddVersion({productId, versions, setVersions, updateVersions, setUpdateVersions}) {

    const [renderVersions, setRenderVersions] = useState(null);

    useEffect(() => {
        if (versions == null) return;
        setRenderVersions(versions.map((e) => {
            return (
                <div key={uuidv4()} className='version'>
                    <div className='name'>{e.title}</div>
                    <StandartButton style={{width: `100%`, height: `75px`, border: `3.175px solid #545454`}} onClick={() => {window.location.href = `/admin/editProduct/${productId}/editVersion/${e.id}`}}>
                        Настроить
                    </StandartButton>
                </div>
            );
        }));
    }, [versions]);

    const addVersions = async () => {
        try {
            const formData = new FormData();
            formData.append('id', productId);
            formData.append('token', Cookies.get('token'));
            const response = await ky.post('https://topkaproduct.com:8443/product/createProductVersion', {
                body: formData,
            }).json();
            
            // console.log(response)
            setUpdateVersions(updateVersions+1);
            // console.log('Upload successful:', response);
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    }

    return (
        <>
            <div className='add-versions'>

                {/* <div className='version'>
                    <div className='name'>Topka hitboxes 1.12.2</div>
                    <StandartButton text='Настроить' style={{width: `100%`, height: `75px`, border: `3.175px solid #545454`}}/>
                </div>

                <div className='version'>
                    <div className='name'>Topka hitboxes 1.12.2</div>
                    <StandartButton text='Настроить' style={{width: `100%`, height: `75px`, border: `3.175px solid #545454`}}/>
                </div> */}
                {renderVersions != null &&
                    <>
                        {renderVersions}
                    </>
                }
                

                <div className='version version-add' onClick={addVersions}>
                    <img src={image} alt='plus'/>
                </div>
                
            </div>
        </>
    );
}

export default AddVersion;