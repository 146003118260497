
import { InputText } from "primereact/inputtext";
import { ColorPicker } from 'primereact/colorpicker';
import './AdminAboutBlocks.css'
import { useState } from "react";
import JoditEditor from "jodit-react";
import HTMLReactParser from "html-react-parser";
import { useEffect } from "react";
import ky from "ky";
import LoadImage from "../../standarts/load-image/LoadImage";
import StandartButton from "../../standarts/button/StandartButton";
import Cookies from "js-cookie";

function AdminAboutBlock({ mainPageJson }) {

    const editor = useState(null);

    const [title, setTitle] = useState(mainPageJson.about_title);
    const [desc, setDesc] = useState(mainPageJson.about_description);
    const [downloadCount, setDownloadCount] = useState(mainPageJson.about_download_count);
    const [downloadColorTop, setDownloadColorTop] = useState(mainPageJson.about_download_top_color);
    const [downloadColorBottom, setDownloadColorBottom] = useState(mainPageJson.about_download_bottom_color);
    const [downloadColor, setDownloadColor] = useState(mainPageJson.about_download_color);
    const [aboutPhoto, setAboutPhoto] = useState(mainPageJson.about_photo);
    const [feedbackCount, setFeedbackCount] = useState(mainPageJson.about_feedback_count);
    const [feedbackColorTop, setFeedbackColorTop] = useState(mainPageJson.about_feedback_top_color);
    const [feedbackColor, setFeedbackColor] = useState(mainPageJson.about_feedback_color);
    const [feedbackColorBottom, setFeedbackColorBottom] = useState(mainPageJson.about_feedback_bottom_color);
    const [feedbackHref, setFeedbackHref] = useState(mainPageJson.about_feedback_href);

    const [config] = useState({
        theme: 'dark',
        controls: {
            font: {
                list: {
                    'QuinqueFive': 'QuinqueFive',
                    'Upheaval Pro': 'Upheaval Pro',
                    'Mazzard Medium': 'Mazzard Medium',
                    'Mazzard Regular': 'Mazzard Regular',
                    'Mazzard SemiBold': 'Mazzard SemiBold'
                }
            }
        }
    });

    const onClickReset = () => {
        setAboutPhoto(mainPageJson.about_photo);
    }

    const clickSend = async () => {
        try {
            const jsn = {
                about_title: title,
                about_description: desc,
                about_download_color: downloadColor,
                about_download_top_color: downloadColorTop,
                about_download_bottom_color: downloadColorBottom,
                about_download_count: downloadCount,
                about_feedback_color: feedbackColor,
                about_feedback_top_color: feedbackColorTop,
                about_feedback_bottom_color: feedbackColorBottom,
                about_feedback_count: feedbackCount,
                about_feedback_href: feedbackHref,
                about_photo: aboutPhoto
            };
            
            let json = JSON.stringify(jsn);

            const formData = new FormData();
            formData.append('aboutInfo', json);
            formData.append('token', Cookies.get('token'));
            
            const response = await ky.post('https://topkaproduct.com:8443/mainpage/setAboutInfo', {
                body: formData,
            }).json();

            if (response.status === "error") {
                return;
            }

            // console.log(response);
            alert('Good!!1!11!11')
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    }

    return (
        <>
            <div className='admin-title-box'>
                <div className='green-box' />
                <div className='text'>Второй блок</div>
            </div>
            <div className="admin-about">

                <div className="first-box">
                    <div className='mini-title'>Заголовок блока</div>
                    <div className="custom-text-box">
                        <InputText className="text-box" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    

                    <div className='mini-title'>Описание</div>
                    <JoditEditor
                        className="jodit-text-box"
                        ref={editor}
                        value={desc}
                        config={config}
                        onChange={(newContent) => setDesc(newContent)}
                    />
        
                    <div className='mini-title'>Кол-во скачиваний</div>
                    <div className="custom-text-box">
                        <InputText className="text-box" value={downloadCount} onChange={(e) => setDownloadCount(e.target.value)} />
                    </div>
                    

                    <div className='mini-title'>Верхний цвет кнопки</div>
                    <ColorPicker value={downloadColorTop} onChange={(e) => setDownloadColorTop('#' + e.value)} />
                    <div className="custom-text-box">
                        <InputText className="text-box" value={downloadColorTop} onChange={(e) => setDownloadColorTop(e.target.value)} />
                    </div>
                    

                    <div className='mini-title'>Цвет кнопки</div>
                    <ColorPicker value={downloadColor} onChange={(e) => setDownloadColor('#' + e.value)} />
                    <div className="custom-text-box">
                        <InputText className="text-box" value={downloadColor} onChange={(e) => setDownloadColor(e.target.value)} />
                    </div>
                    

                    <div className='mini-title'>Нижний цвет кнопки</div>
                    <ColorPicker value={downloadColorBottom} onChange={(e) => setDownloadColorBottom('#' + e.value)} />
                    <div className="custom-text-box">
                        <InputText className="text-box" value={downloadColorBottom} onChange={(e) => setDownloadColorBottom(e.target.value)} />
                    </div>
                    

                    <div className='mini-title'>Пример кнопки</div>
                    <div className="block-button-shell" >
                        <div className="block-button" style={{
                            borderTop: `2.595px solid ${downloadColorTop}`,
                            borderBottom: `5.189px solid ${downloadColorBottom}`,
                            background: `${downloadColor}`
                        }}>
                            <div className="button-text">{downloadCount} Скачиваний</div>
                        </div>
                    </div>
                    
                    

                </div>

                <div className="second-box">
                    <LoadImage photoHash={aboutPhoto} setPhoto={setAboutPhoto} onClickReset={onClickReset} />

                    <div className='mini-title'>Кол-во отзывов</div>
                    <div className="custom-text-box">
                        <InputText className="text-box" value={feedbackCount} onChange={(e) => setFeedbackCount(e.target.value)} />
                    </div>
                    

                    <div className='mini-title'>Верхний цвет кнопки</div>
                    <ColorPicker value={feedbackColorTop} onChange={(e) => setFeedbackColorTop('#' + e.value)} />
                    <div className="custom-text-box">
                        <InputText className="text-box" value={feedbackColorTop} onChange={(e) => setFeedbackColorTop(e.target.value)} />
                    </div>
                    

                    <div className='mini-title'>Цвет кнопки</div>
                    <ColorPicker value={feedbackColor} onChange={(e) => setFeedbackColor('#' + e.value)} />
                    <div className="custom-text-box">
                        <InputText className="text-box" value={feedbackColor} onChange={(e) => setFeedbackColor(e.target.value)} />
                    </div>
                    
                    <div className='mini-title'>Нижний цвет кнопки</div>
                    <ColorPicker value={feedbackColorBottom} onChange={(e) => setFeedbackColorBottom('#' + e.value)} />
                    <div className="custom-text-box">
                        <InputText className="text-box" value={feedbackColorBottom} onChange={(e) => setFeedbackColorBottom(e.target.value)} />
                    </div>
                    

                    <div className='mini-title'>Пример кнопки</div>
                    <div className="block-button-shell" >
                        <div className="block-button" style={{
                            borderTop: `2.595px solid ${feedbackColorTop}`,
                            borderBottom: `5.189px solid ${feedbackColorBottom}`,
                            background: `${feedbackColor}`
                        }}>
                            <div className="button-text">{feedbackCount} Отзывов</div>
                        </div>
                    </div>

                    <div className='mini-title'>Куда ведёт</div>
                    <div className="custom-text-box">
                        <InputText className="text-box" value={feedbackHref} onChange={(e) => setFeedbackHref(e.target.value)} />
                    </div>
                    
                </div>
                
                <StandartButton style={{width: `100%`, height: `75px`, border: `3.998px solid #22BE76`, marginTop: `65px`}} onClick={clickSend}>Сохранить</StandartButton>

                {/* <div className="block-button-shell" style={{width: '100%'}}>
                    <div className="block-button" onClick={clickSend} style={{
                        border: `3.998px solid #22BE76`,
                        width: '100%',
                        marginTop: '35px'
                    }}>
                        <div className="button-text">Сохранить</div>
                    </div>
                </div> */}
                
            </div>
            
        </>
    );
}

export default AdminAboutBlock;