

import StandartButton from '../../../standarts/button/StandartButton';
import './FeedBack.css'
import { Rating } from "primereact/rating";

function FeedBackUser ({photo_hash, date, rating, name, redirect_link, text}) {
    return (
        <>
            <div className='feedback'>
                <div className='upper-box'>
                    <div className='main-info-box'>
                        <img className='image' src= { `https://topkaproduct.com:8443/files/image/${photo_hash}` } />
                        <div className='date'>{date}</div>
                        <div className='name'>{name}</div>
                    </div>
                    <div className='rating'>
                        <Rating value={rating} readOnly cancel={false} />
                    </div>
                </div>
                <div className='text'>
                    {text}
                </div>
                <StandartButton className={'button-style-1'}>Читать дальше</StandartButton>
            </div>
        </>
    );
}

export default FeedBackUser;