import { useEffect, useState } from "react";
import AdminSettingsBlock from "../admin-settings-block/AdminSettingsBlock";
import { InputText } from "primereact/inputtext";

import './AdminPaidProductBlock.css'
import ky from "ky";
import AdminSettingsAddBlock from "../admin-settings-block/AdminSettingsAddBlock";
import StandartButton from "../../standarts/button/StandartButton";
import Cookies from "js-cookie";

function AdminPaidProductBlock({ mainPageJson, products, addNew }) {

    const [title, setTitle] = useState(mainPageJson.paid_product_title);
    const [productsBlock, setProductsBlock] = useState([]);

    const clickSend = async () => {
        try { 
            const formData = new FormData();
            formData.append('paid_product_title', title);
            formData.append('token', Cookies.get('token'));
            
            const response = await ky.post('https://topkaproduct.com:8443/mainpage/setAboutPaidTitle', {
                body: formData,
            }).json();

            if (response.status === "error") {
                return;
            }

            // console.log(response);
            alert('Good!!1!11!11')
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    }

    useEffect(() => {
        if (products == null || products == undefined) return;
        setProductsBlock(products.map((obj) => {
            return (
                <>
                    {obj.paid &&
                        <AdminSettingsBlock name={obj.name} onClick={() => { window.location.href = `/admin/editProduct/${obj.id}`; }} />
                    }
                </>
            );
        }));
    }, [products]);

    return(
        <>
            <div className="admin-paid-product">
                <div className='admin-title-box'>
                    <div className='green-box' />
                    <div className='text'>Третий блок</div>
                </div>

                <div className="title">
                    <div className='mini-title'>Заголовок блока</div>
                    <div className="custom-text-box">
                        <InputText className="text-box" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    
                </div>

                <div className="product-block">
                    {productsBlock}
                    <AdminSettingsAddBlock name='Добавить модификацию' onClick={() => addNew(true)} />
                </div>
                <StandartButton style={{width: `100%`, height: `75px`, border: `3.998px solid #22BE76`, marginTop: '35px'}} onClick={clickSend}>Сохранить</StandartButton>
                {/* <div className="block-button-shell" style={{width: '100%'}}>
                    <div className="block-button" onClick={clickSend} style={{
                        border: `3.998px solid #22BE76`,
                        width: '100%',
                        marginTop: '35px'
                    }}>
                        <div className="button-text">Сохранить</div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default AdminPaidProductBlock;