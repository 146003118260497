
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useState } from 'react';
import { ColorPicker } from 'primereact/colorpicker';
import CheckBox from '../../standarts/check-box/CheckBox';
import { useParams } from 'react-router';
import { InputText } from 'primereact/inputtext';
import StandartButton from '../../standarts/button/StandartButton';
import LoadImage from '../../standarts/load-image/LoadImage';

import './AdminEditCarousel.css'
import ky from 'ky';
import Cookies from 'js-cookie';

function AdminEditCarousel() {
    const { carouselId } = useParams();

    const [resp, setResponse] = useState([]);

    const [title, setTitle] = useState('');
    const [desc, setDesc] = useState('');
    const [button_name, setButtonName] = useState('');
    const [button_color, setButtonColor] = useState('');
    const [button_link, setButtonLink] = useState('');
    const [image, setImage] = useState('');
    const [background_image, setBackgroundImage] = useState('');
    const [state, setState] = useState('');
    const [in_cards, setInCards] = useState('');

    useEffect(() => {
        async function fetchData() {

            const formData = new FormData();
            formData.append('id', carouselId);
            formData.append('token', Cookies.get('token'));
            const response = (await ky.post('https://topkaproduct.com:8443/carousel/getCarouselById', {
                body: formData,
            }).json());

            if (response.status === "error") {
                // console.log(response)
                return;
            }

            setTitle(response.title);
            setDesc(response.desc);
            setButtonName(response.button_text);
            setButtonColor(response.button_color);
            setButtonLink(response.button_link);
            setImage(response.image);
            setBackgroundImage(response.background);
            setState(response.state);
            setInCards(response.in_cards);
            setResponse(response);
        }
        fetchData().then(r => r)
    }, []);

    const saveEdit = async () => {
        try {
            const jsn = {
                id: carouselId,
                title: title,
                desc: desc,
                button_text: button_name,
                button_color: button_color,
                button_link: button_link,
                image: image,
                background: background_image,
                state: state,
                in_cards: in_cards
            };
            
            let json = JSON.stringify(jsn);

            const formData = new FormData();
            formData.append('carousel', json);
            formData.append('token', Cookies.get('token'));

            const response = await ky.post('https://topkaproduct.com:8443/carousel/editCarousel', {
                body: formData,
            }).json();

            if (response.status === "error") {
                return;
            }

            // console.log(response);
            alert('Good!!1!11!11')
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    }

    const resetImage = () => {
        setImage(resp.image);
    }

    const resetBackground = () => {
        setBackgroundImage(resp.background);
    }

    return (
        <>
            <div className="admin-edit-carousel">

                <div className='admin-title-box'>
                    <div className='green-box' />
                    <div className='text'>Создание/настройка версии</div>
                </div>
                <div className="boxes">
                    <div className="first-box">
                        <div className='mini-title'>Заголовок блока</div>
                        <div className="custom-text-box">
                            <InputText className="text-box" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>

                        <div className='mini-title'>Описание</div>
                        <div className="custom-text-box">
                            <InputTextarea className="text-box" style={{maxWidth: `600px`}} value={desc} onChange={(e) => setDesc(e.target.value)} />
                        </div>

                        <div className='mini-title'>Картинка</div>
                        <LoadImage photoHash={image} setPhoto={setImage} onClickReset={resetImage} />

                        <div className='mini-title'>Задний фон (рекомендуемое разрешение 1920x760)</div>
                        <LoadImage photoHash={background_image} setPhoto={setBackgroundImage} onClickReset={resetBackground} />
                    </div>

                    <div className="second-box">
                        <div className='mini-title'>Текст кнопки</div>
                        <div className="custom-text-box">
                            <InputText className="text-box" value={button_name} onChange={(e) => setButtonName(e.target.value)} />
                        </div>

                        <div className='mini-title'>Цвет кнопки</div>
                        <ColorPicker value={button_color} onChange={(e) => setButtonColor('#' + e.value)} />
                        <div className="custom-text-box">
                            <InputText className="text-box" value={button_color} onChange={(e) => setButtonColor(e.target.value)} />
                        </div>

                        <div className='mini-title'>Ссылка кнопки</div>
                        <div className="custom-text-box">
                            <InputText className="text-box" value={button_link} onChange={(e) => setButtonLink(e.target.value)} />
                        </div>

                        <div className='mini-title'>Идёт в карточки или на главную страницу</div>
                        <CheckBox state={in_cards} setState={setInCards} />

                        <div className='mini-title'>Видна ли обычным пользователям (не админам)</div>
                        <CheckBox state={state} setState={setState} />
                    </div>
                </div>

                <StandartButton style={{width: `100%`, height: `75px`, border: `4px solid #08834A`}} onClick={() => {saveEdit()}}>Сохранить</StandartButton>
            </div>
        </>
    );
}

export default AdminEditCarousel;