import { useEffect, useState } from "react";
import "./Profile.css";
import ky from "ky";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import { InputText } from "primereact/inputtext";

import exit_icon from "./exit-button.svg";
import update_image from "./update_image.svg";
import download_icon from "./download_icon2.svg";
import buy_icon from "./buy_icon.svg";

import StandartButton from "../../standarts/button/StandartButton";

function Profile({ setRenderVkMessage, openDialogSubscribe }) {
  const [name, setName] = useState("******");
  const [last_name, setLastName] = useState("******");
  const [nickname, setNickname] = useState("cheatershow");
  const [photo, setPhoto] = useState(
    "https://vk.com/away.php?to=https%3A%2F%2Fvk.com%2Fimages%2Fcamera_400.png"
  );
  const [joinDate, setJoinDate] = useState("**.**.****");

  const [hwid, setHwid] = useState(null);
  const [haveHwid, setHaveHwid] = useState(false);

  const [products, setProducts] = useState([]);
  const [productsRender, setRenderProducts] = useState(0);

  const resetHwid = async () => {
    if (name === "******") return;
    async function fetchData() {
      const formData = new FormData();
      formData.append("token", Cookies.get("token"));
      formData.append("id", -1);

      const response = await ky
        .post(`https://topkaproduct.com:8443/product/getPaymentLink`, {
          body: formData,
        })
        .json();

      if (response.status == "error") {
        return;
      }

      window.location.href = response.link;
    }
    fetchData().then((r) => r);
  };

  useEffect(() => {
    setTimeout(() => {
      const cookieToken = Cookies.get("token");

      async function fetchData() {
        const formData = new FormData();
        formData.append("token", cookieToken);

        const response = await ky
          .post(`https://topkaproduct.com:8443/user/getInfo`, {
            body: formData,
          })
          .json();

        if (response.status == "error") {
          window.location.href =
            "https://oauth.vk.com/authorize?client_id=51782073&display=page&redirect_uri=https://topkaproduct.com/join&scope=262148&response_type=code&v=5.131";
        } else {
          setName(response.name);
          setLastName(response.last_name);
          setNickname(response.nickname);
          setPhoto(response.photo);
          const date = response.first_join_date.split(" ")[0].split("-");
          setJoinDate(date[2] + "." + date[1] + "." + date[0]);
          if (!(response.hwid == undefined || response.hwid == null)) {
            setHaveHwid(true);
          }
          setHwid(response.hwid);
        }
      }
      fetchData().then((r) => r);
    }, 500);
  }, []);

  useEffect(() => {
    if (name === "******") return;
    async function fetchData() {
      const formData = new FormData();
      formData.append("token", Cookies.get("token"));

      const response = await ky
        .post(`https://topkaproduct.com:8443/user/getPurchasedProducts`, {
          body: formData,
        })
        .json();

      if (response.status == "error") {
      }

      setProducts(response.products);
    }
    fetchData().then((r) => r);
  }, [name]);

  useEffect(() => {
    if (products == null || products == undefined) return;
    setRenderProducts(
      products.map((e) => {
        return (
          <div key={uuidv4()}>
            {/* <img src={``} alt="image" /> */}
            <ProfileProduct
              icon={e.svg_mini_icon}
              name={e.title}
              api={e.api}
              file={e.file}
              instruction_video={e.instruction_video}
              openDialogSubscribe={openDialogSubscribe}
            />
          </div>
        );
      })
    );
  }, [products]);

  function exit() {
    Cookies.remove("token");
    window.location.href = "/";
  }

  const saveHwid = async () => {
    try {
      const formData = new FormData();
      formData.append("hwid", hwid);
      formData.append("token", Cookies.get("token"));

      const response = await ky
        .post("https://topkaproduct.com:8443/user/setHwid", {
          body: formData,
        })
        .json();

      if (response.status === "error") {
        alert("Хвид введён не верно!");
        return;
      }

      // console.log(response);
      setHaveHwid(true);
    } catch (error) {
      // console.error('Upload failed:', error);
    }
  };

  return (
    <>
      {/* <div id="vk_community_messages" style={openWidget ? {} : {display: "none"}}></div> */}

      <div className="profile-box">
        <div className="box-with-info">
          <div className="title-exit-box">
            <span>Ваш профиль</span>
            <button className="button-exit" onClick={exit}>
              <img className="icon" src={exit_icon} />
            </button>
          </div>
          <div className="avatar-with-info-box">
            <img src={photo} alt="avatar" className="avatar" />
            <div className="info-box">
              <div className="text-box">
                <span className="left">Имя</span>
                <span className="right">
                  {name} {last_name}
                </span>
              </div>

              <div className="text-box">
                <span className="left">С нами с</span>
                <span className="right">{joinDate}</span>
              </div>

              <div className="text-box">
                <span className="left">Промо</span>
                <span className="right">{nickname}</span>
              </div>

              <div className="text-box">
                <span className="left">Модификаций</span>
                <span className="right">{products.length}</span>
              </div>

              <div className="text-box">
                <span className="left">VK ID</span>
                <span className="right">@{nickname}</span>
              </div>
            </div>
          </div>

          {/* {products.length > 0 && !haveHwid && (
            <>
              <div className="hwid">
                <InputText
                  placeholder="Вставьте свой хвид"
                  className="custom-text-box"
                  value={hwid == undefined ? "" : hwid}
                  onChange={(e) => setHwid(e.target.value)}
                />
                <div className="block-button-shell" onClick={saveHwid}>
                  <div className="block-button">
                    <div className="button-text">Сохранить хвид</div>
                  </div>
                </div>
              </div>
            </>
          )} */}

          {/* <div className="block-button-shell" onClick={exit}>
            <div className="block-button">
              <div className="button-text">Выход</div>
            </div>
          </div> */}
        </div>

        <div className="first-box">
          <div className="title">Опции</div>

          <div className="text-box">
            <div className="left">Техническая поддержка</div>

            <div className="right">
              <StandartButton
                className={"button-style-4"}
                onClick={() => {
                  window.location.href = "https://vk.me/topkaproduct";
                }}
              >
                <div className="button-text">Написать</div>
              </StandartButton>
            </div>
          </div>

          <div className="text-box">
            <div className="left">HWID</div>

            <div className="right">
              <StandartButton
                className={"button-style-4"}
                onClick={() => resetHwid}
              >
                <div className="button-text">Сменить</div>
              </StandartButton>

              <StandartButton
                className={"button-style-1"}
                onClick={() => resetHwid}
              >
                <div className="button-text">Добавлен</div>
              </StandartButton>
            </div>
          </div>

          <div className="text-box">
            <div className="left">Смена HWID'a</div>

            <div className="right">
              <div className="p-inputgroup flex-1 reset-hwid">
                <InputText
                  className="input-box-hwid"
                  placeholder="Введите новый HWID"
                />
                <button className="buy-button">
                  <img src={buy_icon} className="buy-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <img className="update-image" src={update_image} />

        <div className="second-box">
          <div className="title">Купленные моды</div>

          <div className="buy-products">{productsRender}</div>
        </div>
      </div>

      {/* <div className="iframe-box" style={{height: "694px"}}> //CARDS
            <iframe src="https://topkaproduct.com/card?block=myprizes" width="100%"
            height="100%"
            frameBorder="0"
            style={{
                border: '0',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%'
            }}
            allowFullScreen />
        </div> */}
    </>
  );
}

function ProfileProduct({
  icon,
  name,
  api,
  file,
  instruction_video,
  openDialogSubscribe,
}) {
  const dwnload = async (fileUrl) => {
    if (file === "needSubscribeBrat :)") {
      openDialogSubscribe(true);
      return;
    }
    const link = document.createElement("a");
    link.href = fileUrl;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="text-box">
        <div className="left">
          <img
            src={`https://topkaproduct.com:8443/files/image/${icon}`}
            className="svg-icon"
          />
          {name}
          <span className="api">({api})</span>
        </div>

        <div className="right">
          <div className="right-zone">
            <div className="button-boxes">
              <StandartButton
                className={"button-style-4"}
                onClick={() => (window.location.href = instruction_video)}
              >
                <div className="button-text">Инструкция</div>
              </StandartButton>

              <StandartButton
                className={"button-style-4 button-image"}
                onClick={() =>
                  dwnload(
                    `https://topkaproduct.com:8443/files/download/${file}`
                  )
                }
              >
                <img className="download-icon" src={download_icon} />
              </StandartButton>

              <StandartButton
                className={"button-style-4 download-button"}
                onClick={() => (window.location.href = instruction_video)}
              >
                <div className="button-text">Скачать</div>
              </StandartButton>
            </div>

            {/* <div
              className="block-button-shell"
              onClick={() =>
                dwnload(`https://topkaproduct.com:8443/files/download/${file}`)
              }
            >
              <div className="block-button">
                <div className="button-text">Скачать</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
