
import { useEffect, useState } from 'react';
import './LoadImage.css'
import ky from 'ky';
import Cookies from 'js-cookie';

function LoadImage({photoHash, setPhoto, onClickReset}) {

    const [file, setFile] = useState(null);

    const handleFileSelection = async () => {
        try {
            const input = document.createElement('input');
            input.type = 'file';

            // Listen for the file selection
            input.addEventListener('change', (event) => {
                const selectedFile = event.target.files[0];
                setFile(selectedFile);
            });

            // Trigger the file selection dialog
            input.click();
        } catch (error) {
            console.error('File selection error:', error);
        }
    };

    const handleUpload = async () => {
        if (!file) {
            console.error('No file selected');
            return;
        }

        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('token', Cookies.get('token'));
            const response = await ky.post('https://topkaproduct.com:8443/files/upload', {
                body: formData,
            }).json();

            setTimeout(() => {
                setPhoto(response.article);
            }, 1000);
            // console.log('Upload successful:', response);
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    };

    useEffect(() => {
        if (file != null) handleUpload();
    }, [file]);

    return (
        <>
            <div className="change-photo-box">
                <div className="photo-box">
                    {!(photoHash == '' || photoHash == null || photoHash == undefined) &&
                        <img className="photo" src={`https://topkaproduct.com:8443/files/image/${photoHash}`} />
                    }
                </div>

                <div className="button-box">
                    <div className='button' onClick={handleFileSelection} style={{
                        border: '4px solid #08834A'
                    }}>
                        <div className='button-text'>Загрузить</div>
                    </div>

                    <div className='button' onClick={onClickReset} style={{
                        border: '4px solid #EA3737'
                    }}>
                        <div className='button-text'>Откатить</div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default LoadImage;