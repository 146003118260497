
import AboutBlock from "../../blocks/about-block/AboutBlock";
import FreeProductBlock from "../../blocks/free-product-block/FreeProductBlock";
import MainBlock from "../../blocks/main-block/MainBlock";
import PaidProductBlock from "../../blocks/paid-product-block/PaidProductBlock";
import Title from "../../title/Title";
import { Carousel } from "../../standarts/carousel/Carousel";
import { useEffect, useState } from "react";
import ky from "ky";
import Cookies from "js-cookie";
import Feedback from "../../feedback/Feedback";
import FeedBackBlock from "../../blocks/feedback-block/FeedbackBlock";

function MainPage() {

    const [aboutInfo, setAboutInfo] = useState(null);

    const [carousels, setCarousels] = useState(null);
    const [renderCarousels, setRenderCarousles] = useState(null);

    useEffect(() => {
        async function fetchData() {

            const response = (await ky.post('https://topkaproduct.com:8443/mainpage/getAboutInfo').json());

            if (response.status === "error") {
                // console.log(response)
                return;
            }

            setAboutInfo(response);
        }
        fetchData().then(r => r)
        async function fetchData2() {
            const formData = new FormData();
            formData.append('token', Cookies.get('token'));
            const response = (await ky.post('https://topkaproduct.com:8443/carousel/getCarousels', {
                body: formData,
            }).json());

            if (response.status === "error") {
                // console.log(response)
                return;
            }

            setCarousels(response);
        }
        fetchData2().then(r => r)
    }, []);

    useEffect(() => {
        if (carousels == null) return;
        
        setRenderCarousles(carousels.carousels.
            filter((e) => {
                return !e.in_cards;
            }).
            map((e) => {
            return (
                <MainBlock name={e.title} description={e.desc} buttonText={e.button_text} image={e.image} backgroundImage={e.background} buttonLink={e.button_link} />
            );
        }));
    }, [carousels]);

    return(
    <>
    
    {renderCarousels != null &&
        <Carousel>
            {/* <MainBlock name='Модификация Topka Health' description='Один из самых популярных модов нашей студии. доступен для бесплатного скачивания!' buttonText='Скачать' miniImageName='test.png' bigImageName='block/test.svg'/>
            <MainBlock name='Модификация Topka Health2' description='Один из самых популярных модов нашей студии. доступен для бесплатного скачивания!' buttonText='Скачать' miniImageName='test.png' bigImageName='block/test.svg'/> */}
            {/* <MainBlock name='Модификация Topka Health' description='Один из самых популярных модов нашей студии. доступен для бесплатного скачивания!' buttonText='Скачать' miniImageName='test.png' bigImageName='block/test.svg'/> */}
            {renderCarousels}
            
        </Carousel>
    }

    <AboutBlock title={aboutInfo?.about_title} about_description={aboutInfo?.about_description} about_download_count={aboutInfo?.about_download_count} about_download_color={aboutInfo?.about_download_color}
    about_download_top_color={aboutInfo?.about_download_top_color} about_download_bottom_color={aboutInfo?.about_download_bottom_color}
    about_feedback_color={aboutInfo?.about_feedback_color} about_feedback_count={aboutInfo?.about_feedback_count} 
    about_feedback_top_color={aboutInfo?.about_feedback_top_color} about_feedback_bottom_color={aboutInfo?.about_feedback_bottom_color}
    about_photo={aboutInfo?.about_photo} about_feedback_href={aboutInfo?.about_feedback_href} />

    <Title title={aboutInfo?.paid_product_title} style={{
        // borderTop: `3.892px solid #FFD98E`,
        // borderBottom: `6.486px solid #FFD98E`,  
        background: `var(--ffb-932, #FFB932)`
    }} />

    <PaidProductBlock />
    
    <Title title={aboutInfo?.free_product_title} style={{
        // borderTop: `3.892px solid #39BC7F`,
        // borderBottom: `5.189px solid #39BC7F`,
        background: `#08834A`
    }} />

    <FreeProductBlock />

    <FeedBackBlock />

    </>
    );
}

export default MainPage;
