import { useEffect, useState } from "react";

function DynamicImage({folder, mode, name, className, onClick}) {
    
    const [cls, setCls] = useState('');
    useEffect(() => {
        setCls('header-nav-menu-icon' + (className == undefined ? '' : (' ' + className)));
    }, [className]);
    
    return (
        <>
        {name != null && name != undefined && name != '' &&
            <img src={(mode == undefined || mode == 1 ? `../img/${folder}/${name}.svg` : `https://topkaproduct.com:8443/files/image/${name}`)} 
                        className={cls}
                        alt="Img"
                        onClick={onClick}/>
        }
        </>
    );
}

export default DynamicImage;
