
import './StandartButton.css'

function StandartButton({children, onClick, className}) {
    return (
        <>
            <button className={className == undefined ? 'button' : `button ${className}`} onClick={onClick}>
                {children}
            </button>
        </>
    );
}

export default StandartButton;