
import './OtherProduct.css'

import link_icon from './link_icon.svg'

function OtherProduct ({id, name, desc, svg_mini_icon}) {

    return (
        <>
            <div className="other-version">
                <div className='title-boxes'>
                    <div className='img-box'>
                        <img className="img" src={`https://topkaproduct.com:8443/files/image/${svg_mini_icon}`} alt="mini icon" />
                    </div>

                    <div className='api'>
                        <img src={link_icon} />
                    </div>
                </div>
                <div className="name">{name}</div>
                <div className='desc'>{desc}</div>
                
            </div>
        </>
    );
}

export default OtherProduct;