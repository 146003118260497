import './AdminSettingsBlock.css'
import { v4 as uuidv4 } from 'uuid';

function AdminSettingsBlock({key, name, onClick}) {
    return(
        <>
        {key == undefined && 
            <div className='settings-box' key={uuidv4()}>
                <div className='name'>{name}</div>

                <div className='button' onClick={onClick}>
                    <div className='button-text'>Настроить</div>
                </div>
            </div>
        }
        </>
    );
}

export default AdminSettingsBlock;