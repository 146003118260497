
import HTMLReactParser from 'html-react-parser';
import Title from '../../title/Title';
import './AboutBlock.css'

function AboutBlock({title, about_description, about_download_count, about_download_color, about_download_top_color, about_download_bottom_color, 
    about_feedback_count, about_feedback_color, about_feedback_top_color, about_feedback_bottom_color ,
    about_photo, about_feedback_href}) {

function clickAboutFeedback() {
    window.location.href = `${about_feedback_href}`;
}

return (
<>
<div className='about-block'>
{/* <div className='about-title'>{title}</div> */}
<Title title={title} style={{background: `#151515`}} />
<div className='about-main-box'>
    <div className='about-main-box-left'>
        <div className='block-text'>
            <div className='text'>Более</div>
            <div className='text number'>{about_feedback_count}</div>
            <div className='text'>отзывов</div>
        </div>
        <div className='block-text orange'>
            <div className='text'>Более</div>
            <div className='text number'>{about_download_count}</div>
            <div className='text'>скачиваний</div>
        </div>
        <img className='about-main-left-image' src={`https://topkaproduct.com:8443/files/image/${about_photo}`}/>
    </div>
    <div className='about-main-box-right'>
        <div className='about-main-text'>
            <div className='about-main-info'>
                {about_description != undefined &&
                    <div>{HTMLReactParser(about_description)}</div>
                }
            </div>
            {/* <div className='about-main-info'>
                <a>Мы - </a>
                <a className='select'>Лучшая студия разработки</a>
            </div>
            <div className='about-main-info'>
                <a className='select'>модификаций </a>
                <a>для игры Minecraft.</a>
            </div>
            <div className='about-main-info'>
                <a>Наши модификации насчитывают более</a>
            </div>
            <div className='about-main-info'>
                <a className='select'>20 тысяч </a>
                <a>пользователей и более </a>
                <a className='select'>100</a>
            </div>
            <div className='about-main-info'>
                <a className='select'>тысяч </a>
                <a>скачиваний.</a>
            </div>
            <div className='about-main-info'>
                <a>Мы - </a>
                <a className='select'>Лучшая студия разработки</a>
            </div>
            <div className='about-main-info'>
                <a className='select'>модификаций </a>
                <a>для игры Minecraft.</a>
            </div>
            <div className='about-main-info'>
                <a>Наши модификации насчитывают более</a>
            </div>
            <div className='about-main-info'>
                <a className='select'>20 тысяч </a>
                <a>пользователей и более </a>
                <a className='select'>100</a>
            </div>
            <div className='about-main-info'>
                <a className='select'>тысяч </a>
                <a>скачиваний.</a>
            </div> */}
        </div>

        {/* <div className='button-blocks'>
            <div className="block-button-shell" >
                <div className="block-button" style={{
                borderTop: `2.595px solid ${about_download_top_color}`,
                borderBottom: `5.189px solid ${about_download_bottom_color}`,
                background: `${about_download_color}`
                }}>
                    <div className="button-text">{about_download_count} Скачиваний</div>
                </div>
            </div>

            <div className="block-button-shell">
                <div className="block-button" onClick={clickAboutFeedback} style={{
                borderTop: `2.595px solid ${about_feedback_top_color}`,
                borderBottom: `5.189px solid ${about_feedback_bottom_color}`,
                background: `${about_feedback_color}`
                }}>
                    <div className="button-text">{about_feedback_count} Отзыва</div>
                </div>
            </div>
        </div> */}
        

    </div>
</div>
</div>
</>
);
}

export default AboutBlock;