//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

//core
import "primereact/resources/primereact.min.css";

import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import './App.css';
import './AppDialogue.css'
import { Route, Routes } from 'react-router';
import Header from './components/header/Header';
import { useEffect, useState } from "react";
import MainPage from "./components/pages/main-page/MainPage";
import Profile from "./components/pages/profile/Profile";
import TopkaFooter from './components/footer/TopkaFooter'
import Join from "./components/pages/main-page/Join";
import AdminPage from "./components/pages/admin/AdminPage";
import AdminEditProduct from "./components/pages/edit-product/AdminEditProduct";
import AdminHeader from "./components/header/AdminHeader";
import EditProductVersion from "./components/pages/edit-product-version/EditProductVersion";
import AdminEditCarousel from "./components/pages/edit-carousel/AdminEditCarousel";
import ProductPage from "./components/pages/product-page/ProductPage";
import { Dialog } from 'primereact/dialog';
import SwiperCore from 'swiper';
import { Autoplay } from 'swiper/modules';

import x_icon from './x.svg'

import tp from './tp_logo_group.jpg'
import td from './td_logo_group.jpg'
import tm from './tm_logo_group.jpg'
import ts from './ts_logo_group.jpg'
import MainPageCards from "./components/pages/main-page/MainPageCards";

function App() {
  SwiperCore.use([Autoplay]);
  const [headerIndex, setHeaderIndex] = useState(-1);
  const [subscribeBox, setSubscribeBox] = useState(false);

  // useEffect(() => {
  //     console.log(123)
  // }, []);

  return (
    <>
      {subscribeBox &&
        <>
          <Dialog className="dialog-subscribe" visible={true} style={{ width: '60vw' }} onHide={() => {setSubscribeBox(false);}} header={<></>} closeIcon={
            <img src={x_icon} />
          }>

            <div className="dialogue-subscribe-inside">
              <div className="title">
                Для скачивания - подпишись
              </div>
              <div className="groups">
                <a className="group" href="https://vk.com/mstresurs">
                  <img src={tp} className="group-logo" />
                  <div className="text">Topka product</div>
                </a>
                <a className="group" href="https://vk.com/topkawebdesign">
                  <img src={td} className="group-logo" />
                  <div className="text">Topka design</div>
                </a>
                <a className="group" href="https://vk.com/topkamods">
                  <img src={tm} className="group-logo" />
                  <div className="text">Topka mods</div>
                </a>
                <a className="group" href="https://vk.com/topkaspace">
                  <img src={ts} className="group-logo" />
                  <div className="text">Topka space</div>
                </a>
              </div>
            </div>
          </Dialog>
        </>
      }


      <Routes>

        <Route path='/' element={
          <>
            <Header headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} />
            <MainPage />
          </>
        } />

        {/* <Route path='/cards' element={
          <>
            <Header headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} />
            <MainPageCards />
          </>
        } /> */}

        <Route path='/profile' element={
          <>
            <Header headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} />
            <Profile openDialogSubscribe={setSubscribeBox} />
          </>
        } />

        <Route path='/product/:productId' element={
          <>
            <Header headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} />
            <ProductPage openDialogSubscribe={setSubscribeBox} />
          </>
        } />

        <Route path='/admin' element={
          <>
            <AdminHeader headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} backArrow={false} />
            <AdminPage />
          </>
        } />

        <Route path='/admin/editCarousel/:carouselId' element={
          <>
            <AdminHeader headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} backArrow={true} />
            <AdminEditCarousel />
          </>
        } />

        <Route path='/admin/editProduct/:productId' element={
          <>
            <AdminHeader headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} backArrow={true} />
            <AdminEditProduct />
          </>
        } />

        <Route path='/admin/editProduct/:productId' element={
          <>
            <AdminHeader headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} backArrow={true} />
            <AdminEditProduct />
          </>
        } />

        <Route path="/admin/editProduct/:productId/editVersion/:versionId" element={
          <>
            <EditProductVersion headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} />
          </>
        } />

        <Route path='*' element={
          <>
            <Header headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} />
            <Join />
          </>
        } />

      </Routes>

      <TopkaFooter />
    </>
  );
}

export default App;
