
import './Feedback.css'

import logo from './m2at5oE5fD2I8u3TG3u1ZCFMHbCdZnUkTaTeFRB2-8wFJ24dPBCNWrixPj3gwAYpuAl4kCOAdgW1fyds1da2j87Q.jpg'
import goto_link from './goto_link_icon.svg'

export default function Feedback({name, text}) {
    return (
        <>
            <div className='feedback-main'>
                <img className='image' src={logo} />
                <a className='goto' href='https://vk.com/topic-193648337_49690663'>
                    <img className='icon'  src={goto_link} />
                </a>

                <div className='text-block'>
                    <div className='name'>{name}</div>
                    <div className='text'>{text}</div>
                </div>
            </div>
        </>
    );
}