
import './CheckBox.css'

function CheckBox({state, setState}) {
    return (
        <>
            <div className='check-box' onClick={() => setState(state ^ 1)}>
                <div className={`switch ` + (state == 1 ? `enable` : `disable`)}/>
            </div>
        </>
    );
}

export default CheckBox;