import { useEffect, useState } from "react";
import FreeProduct from "../../free-product/FreeProduct";
import "./FreeProductBlock.css";
import { v4 as uuidv4 } from "uuid";
import ky from "ky";
import Cookies from "js-cookie";

function FreeProductBlock() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const formData = new FormData();
      formData.append("token", Cookies.get("token"));

      const response = await ky
        .post("https://topkaproduct.com:8443/product/getProducts", {
          body: formData,
        })
        .json();

      if (response.status === "error") {
        // console.log(response)
        return;
      }

      setProducts(
        response.products
          .filter((e) => {
            return !e.paid;
          })
          .map((e) => {
            return (
              // <MainBlock name={e.title} description={e.desc} buttonText={e.button_text} image={e.image} backgroundImage={e.background} buttonLink={e.button_link} />
              <FreeProduct
                key={uuidv4()}
                productId={e.id}
                icon={e.svg_mini_icon}
                name={e.name}
                description={e.short_description}
              />
            );
          })
      );
    }
    fetchData().then((r) => r);

    // setProducts([
    //     <FreeProduct key={uuidv4()} icon='topkaHitboxes' name='Topka Hitboxes v2' description='C помощью этого мода вы можете можете менять цвет хитбокса игрока и не только.'/>,
    //     <FreeProduct key={uuidv4()} icon='topkaHitboxes' name='Topka Hitboxes v2' description='C помощью этого мода вы можете можете менять цвет хитбокса игрока и не только.'/>,
    //     <FreeProduct key={uuidv4()} icon='topkaHitboxes' name='Topka Hitboxes v2' description='C помощью этого мода вы можете можете менять цвет хитбокса игрока и не только.'/>
    // ]);
  }, []);

  return (
    <>
      <div className="free-product-block-main" id="free-product">
        <div className="main-box">{products}</div>
      </div>
    </>
  );
}

export default FreeProductBlock;
