
import { useEffect } from 'react';
import './Carousel.css'
import { useState } from 'react';
import { Children } from 'react';
import { cloneElement } from 'react';
import DynamicImage from '../../header/DynamicImage';

export const Carousel = ({children}) => {

    const [pages, setPages] = useState([]);

    const [index, setIndex] = useState(0);
    const [offset, setOffset] = useState(0);

    const onClickLeft = () => {
        if (index == 0) {
            setIndex(-(children.length-1));
        } else {
            setIndex(index + 1);
        }
    }
    
    const onClickRight = () => {
        if (index == -(children.length-1)) {
            setIndex(0);
        } else {
            setIndex(index - 1);
        }
    }

    useEffect(() => {
        setOffset(index * 100);
    }, [index]);

    useEffect(() => {
        setPages(Children.map(children, child => {
            return cloneElement(child, {
            style: {
                height: '100%', 
                minWidth: '100%',
                maxWidth: '100%'
            },
        })
    })
    )
    }, []);

    return (
        <div className="main-container">
            {/* <DynamicImage name='left-arrow' folder='carousel' className='button-arrow left' onClick={onClickLeft}/> */}
            <div className="window">
                <div className="all-pages-container"
                style={{
                    transform: `translateX(${offset}vw)`
                }}>
                    {pages}
                </div>
            </div>
            <DynamicImage name='right-arrow' folder='carousel' className='button-arrow right' onClick={onClickRight}/>
        </div>
    );

}