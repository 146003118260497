
import { useParams } from "react-router";
import './EditProductVersion.css'
import StandartButton from "../../standarts/button/StandartButton";
import { useEffect, useState } from "react";
import CheckBox from "../../standarts/check-box/CheckBox";
import { InputText } from "primereact/inputtext";
import AdminHeader from "../../header/AdminHeader";
import LoadFile from "../../standarts/load-file/LoadFile";
import ky from "ky";
import Cookies from "js-cookie";

function EditProductVersion ({headerIndex, setHeaderIndex}) {
    const { productId, versionId } = useParams();

    const [title, setTitle] = useState('');
    const [api, setAPI] = useState('');
    const [file, setFile] = useState('');
    const [instruction_video, setInstructionVideo] = useState('');
    const [state, setState] = useState(0);

    useEffect(() => {
        async function fetchData() {

            const formData = new FormData();
            formData.append('id', versionId);
            formData.append('token', Cookies.get('token'));

            const response = (await ky.post('https://topkaproduct.com:8443/product/getProductVersionsByIdVersion', {
                body: formData,
            }).json());

            if (response.status === "error") {
                // console.log(response)
                return;
            }

            setTitle(response.title)
            setAPI(response.api)
            setFile(response.file)
            setInstructionVideo(response.instruction_video)
            setState(response.state)
            
        }
        fetchData().then(r => r)
    }, []);

    const saveEdit = async () => {
        try {
            const jsn = {
                id: versionId,
                title: title,
                api: api,
                file: file,
                instruction_video: instruction_video,
                state: state
            };
            
            let json = JSON.stringify(jsn);

            const formData = new FormData();
            formData.append('productVersion', json);
            formData.append('token', Cookies.get('token'));

            const response = await ky.post('https://topkaproduct.com:8443/product/editProductVersion', {
                body: formData,
            }).json();

            if (response.status === "error") {
                return;
            }

            // console.log(response);
            alert('Good!!1!11!11')
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    }

    return (
        <>
            <AdminHeader headerIndex={headerIndex} setHeaderIndex={setHeaderIndex} backArrow={true} backHref={`/admin/editProduct/${productId}`} />
            <div className="admin-edit-product">
                
                <div className='admin-title-box'>
                    <div className='green-box' />
                    <div className='text'>Создание/настройка версии</div>
                </div>
                <div className="boxes">
                    <div className="first-box">
                        <div className='mini-title'>Название версии</div>
                        <div className="custom-text-box">
                            <InputText className="text-box" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>

                        <div className='mini-title'>Загрузить файл</div>
                        <LoadFile setFileVersion={setFile} />
                    </div>

                    <div className="second-box">
                        <div className='mini-title'>API</div>
                        <div className="custom-text-box">
                            <InputText className="text-box" value={api} onChange={(e) => setAPI(e.target.value)} />
                        </div>

                        <div className='mini-title'>Видео-инструкция</div>
                        <div className="custom-text-box">
                            <InputText className="text-box" value={instruction_video} onChange={(e) => setInstructionVideo(e.target.value)} />
                        </div>

                        <div className='mini-title'>Видна ли обычным пользователям (не админам)</div>
                        <CheckBox state={state} setState={setState} />
                    </div>
                </div>

                <StandartButton style={{width: `100%`, height: `75px`, border: `4px solid #08834A`}} onClick={() => {saveEdit()}}>Сохранить</StandartButton>

            </div>
        {/* {productId}
        {versionId} */}
        </>
    );
}

export default EditProductVersion;