import { useEffect, useState } from "react";
import { useParams } from "react-router";

import './AdminEditProduct.css'
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import ky from "ky";
import LoadImage from "../../standarts/load-image/LoadImage";
import CheckBox from "../../standarts/check-box/CheckBox";
import LoadBanner from "../../standarts/load-banner/LoadBanner";
import LoadImagesBox from "../../standarts/load-images-box/LoadImagesBox";
import StandartButton from "../../standarts/button/StandartButton";
import AddVersion from "../../standarts/version-box/AddVersion";
import Cookies from "js-cookie";

function AdminEditProduct() {

    const { productId } = useParams();

    const [products, setProducts] = useState(null);

    const [ name, setName ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ short_description, setShortDescription ] = useState('');
    const [ linkYT, setLinkYT ] = useState('');
    const [ price, setPrice ] = useState('');
    const [ svg_mini_icon, setSvgMiniIcon ] = useState('');
    const [ banner_image, setBannerImage ] = useState('');
    const [ images, setImages ] = useState(undefined);
    const [ paid, setPaid ] = useState('');
    const [ discount_if_have_old, setDiscountIfHaveOld ] = useState('');
    const [ old_version, setOldVersion ] = useState('');
    const [ state, setState ] = useState('');

    const [ versions, setVersions] = useState(null);
    const [ updateVersions, setUpdateVersions] = useState(0);

    useEffect(() => {
        async function fetchData() {
            const formData = new FormData();
            formData.append('id', productId);
            formData.append('token', Cookies.get('token'));

            const response = await ky.post('https://topkaproduct.com:8443/product/getProductVersions', {
                body: formData,
            }).json();

            if (response.status === "error") {
                // console.log(response)
                return;
            }

            setVersions(response.versions);
            // console.log(response.versions)
            // console.log(response)
        }
        fetchData().then(r => r)
    }, [updateVersions]);

    useEffect(() => {
        async function fetchData() {
            const formData = new FormData();
            formData.append('token', Cookies.get('token'));
            const response = (await ky.post('https://topkaproduct.com:8443/product/getProducts', {
                body: formData,
            }).json());

            if (response.status === "error") {
                // console.log(response)
                return;
            }

            setProducts(response.products);
        }
        fetchData().then(r => r)

    }, []);

    useEffect(() => {
        if (products == null) return;
        setName(products[productId-1].name);
        setDescription(products[productId-1].description);
        setShortDescription(products[productId-1].short_description);
        setLinkYT(products[productId-1].link_yt);
        setPrice(products[productId-1].price);
        setSvgMiniIcon(products[productId-1].svg_mini_icon);
        setBannerImage(products[productId-1].banner_image);
        setImages(products[productId-1].images);
        setPaid(products[productId-1].paid);
        setDiscountIfHaveOld(products[productId-1].discount_if_have_old);
        setOldVersion(products[productId-1].old_version);
        setState(products[productId-1].state);
        
    }, [products]);

    const onClickReset = () => {
        setSvgMiniIcon(products[productId-1].svg_mini_icon);
    }

    const saveEdit = async () => {
        try {
            const jsn = {
                id: productId,
                name: name,
                description: description,
                short_description: short_description,
                old_version: old_version,
                link_yt: linkYT,
                price: price,
                paid: paid,
                discount_if_have_old: discount_if_have_old,
                svg_mini_icon: svg_mini_icon,
                banner_image: banner_image,
                state: state,
                images: images
            };
            
            let json = JSON.stringify(jsn);

            const formData = new FormData();
            formData.append('product', json);
            formData.append('token', Cookies.get('token'));

            const response = await ky.post('https://topkaproduct.com:8443/product/editProduct', {
                body: formData,
            }).json();

            if (response.status === "error") {
                return;
            }

            // console.log(response);
            alert('Good!!1!11!11')
        } catch (error) {
            // console.error('Upload failed:', error);
        }
    }

    return (
        <>
            {/* {productId} */}
            <LoadBanner photoHash={banner_image} setPhoto={setBannerImage} />
            <div className="admin-edit-product">
                
                <div className='admin-title-box'>
                    <div className='green-box' />
                    <div className='text'>Создание/настройка модификации</div>
                </div>
                <div className="boxes">

                    <div className="first-box">
                        <div className='mini-title'>Добавить фото</div>
                        <LoadImagesBox photoHashs={images} setPhotoHashs={setImages} />

                        <div className='mini-title'>Название модификации</div>
                        <div className="custom-text-box">
                            <InputText className="text-box" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        
                        <div className='mini-title'>Описание модификации</div>
                        <div className="custom-text-box">
                            <InputTextarea className="text-box" style={{maxWidth: `680px`}} value={description} onChange={(e) => setDescription(e.target.value)} />
                        </div>
                        
                        <div className='mini-title'>Короткое описание</div>
                        <div className="custom-text-box">
                            <InputTextarea className="text-box" style={{maxWidth: `680px`}} value={short_description} onChange={(e) => setShortDescription(e.target.value)} />
                        </div>

                        <div className='mini-title'>Версии</div>
                        <AddVersion productId={productId} versions={versions} setVersions={setVersions} updateVersions={updateVersions} setUpdateVersions={setUpdateVersions}/>
                    </div>

                    <div className="second-box">
                        
                        <div className='mini-title'>Ссылка на видеоролик</div>
                        <div className="custom-text-box">
                            <InputText className="text-box" value={linkYT} onChange={(e) => setLinkYT(e.target.value)} />
                        </div>

                        <div className='mini-title'>SVG иконка</div>
                        <LoadImage photoHash={svg_mini_icon} setPhoto={setSvgMiniIcon} onClickReset={onClickReset} />
                        
                        <div className='mini-title'>Платная модификации</div>
                        <CheckBox state={paid} setState={setPaid} />

                        { paid && 
                        <>
                            <div className='mini-title'>Цена</div>
                            <div className="custom-text-box">
                                <InputText className="text-box" value={price} onChange={(e) => setPrice(e.target.value)} />
                            </div>

                            <div className='mini-title'>Прошлая версия (productId)</div>
                            <div className="custom-text-box">
                                <InputText className="text-box" value={old_version} onChange={(e) => setOldVersion(e.target.value)} />
                            </div>

                            <div className='mini-title'>Скидка если имеется прошлая версия (в %)</div>
                            <div className="custom-text-box">
                                <InputText className="text-box" value={discount_if_have_old} onChange={(e) => setDiscountIfHaveOld(e.target.value)} />
                            </div>
                        </>
                        }

                        <div className='mini-title'>Видна ли обычным пользователям (не админам)</div>
                        <CheckBox state={state} setState={setState} />
                        
                    </div>

                    
                </div>
                <StandartButton style={{width: `100%`, height: `75px`, border: `4px solid #08834A`}} onClick={saveEdit}>Сохранить</StandartButton>
            </div>
        </>
    );
}

export default AdminEditProduct;