import './AdminSettingsBlock.css'
import image from './plus.svg'

function AdminSettingsAddBlock({name, onClick}) {
    return(
        <>
        <div className='settings-box settings-box-add' onClick={onClick}>
            <img src={image} 
                        className="image"
                        alt="plus"
                        />
            
            <div className='text'>{name}</div>
        </div>
        </>
    );
}

export default AdminSettingsAddBlock;