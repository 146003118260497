
import "./Title.css"

function Title ({title, style}) {
    return (
    <>
        <div className='title-block'>
            <div className='title' style={style}>{title}</div>
        </div>
    </>
    );
}

export default Title;